<template>
  <custom-select
    :options="userSelect"
    label="email"
    v-model="user"
    placeholder="wyszukaj po adresie e-mail..."
    @input="filterUsers"
    style="max-width: 500px"
  >
    <!-- eslint-disable-next-line vue/no-unused-vars  -->
    <template #no-options="{ search, searching, loading }">
      Nie znaleziono - wpisz co najmniej 3 litery.
    </template>
  </custom-select>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "UserSelect",
  props: ["defUser"],
  data() {
    return {
      user: null,
    };
  },
  methods: {
    ...mapActions("users", ["getUserSelect", "resetUserSelect"]),
    filterUsers(e) {
      let params = { search: e.target.value };
      setTimeout(() => {
        e.target.value && e.target.value.length > 2
          ? this.getUserSelect({ params }).catch((error) => {
              console.log(error);
            })
          : this.resetUserSelect();
      }, 500);
    },
  },
  watch: {
    user() {
      this.$emit("setUser", this.user);
    },
  },
  computed: {
    ...mapState({
      userSelect: (state) => state.users.userSelect,
    }),
  },
  created() {
    if (this.defUser) this.user = this.defUser;
  },
};
</script>

<style></style>
