<template>
  <div class="text-center">
    <v-text-field
      hide-details="auto"
      v-model="question"
      :label="`Pytanie ${index}`"
    />
    <vue-editor v-model="answer" />
    <v-btn class="mb-3" @click="save()">Zapisz zmiany</v-btn>
    <custom-snackbar :snackbar="snackbar" @clear="updateSnackbar" />
  </div>
</template>

<script>
import CustomSnackbar from "../../components/layoutComponents/CustomSnackbar.vue";
import { mapActions } from "vuex";
export default {
  name: "QnaForm",
  props: ["data", "index"],
  data() {
    return {
      question: "",
      answer: "",
      snackbar: {
        open: false,
        text: "",
        color: "",
      },
    };
  },
  methods: {
    ...mapActions("questions", ["putQuestion"]),
    save() {
      let payload = this.data;
      payload["answear"] = this.answer;
      this.putQuestion([this.data.id, payload])
        .then(() => {
          this.updateSnackbar({
            open: true,
            text: "Pomyślnie zaktualizowano",
            color: "success",
          });
        })
        .catch(() => {
          this.updateSnackbar({
            open: true,
            text: "Nie udało się zaktualizować! Spróbuj ponownie",
            color: "error",
          });
        });
    },
    updateSnackbar(
      msg = {
        open: false,
        text: "",
        color: "",
      }
    ) {
      this.snackbar = msg;
    },
  },
  created() {
    this.question = this.data.question;
    this.answer = this.data.answear;
  },
  components: {
    CustomSnackbar,
  },
};
</script>

<style></style>
