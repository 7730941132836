<template>
  <tr
    :style="
      user
        ? 'background: rgba(var(--v-border-color), var(--v-hover-opacity))'
        : ''
    "
  >
    <td>
      <v-text-field
        :label="user ? 'e-mail' : 'Dodaj nowego użytkownika'"
        variant="underlined"
        v-model="email"
        hide-details="auto"
      />
    </td>
    <template v-if="newCompany">
      <td>
        <v-text-field
          label="nazwa firmy"
          variant="underlined"
          v-model="company"
          hide-details="auto"
        />
      </td>
      <td>
        <v-text-field
          label="regon firmy"
          variant="underlined"
          v-model="regon"
          hide-details="auto"
        />
      </td>
    </template>
    <td v-else colspan="2">
      <company-select @set-company="setCompany" :defCompany="company" />
    </td>
    <td class="text-right">
      <v-btn
        variant="text"
        @click="
          newCompany = !newCompany;
          companyId = null;
        "
        color="primary"
      >
        <span v-if="newCompany">wyszukaj istniejącą firmę</span>
        <span v-else>dodaj nową firmę</span>
      </v-btn>
      <v-tooltip anchor="top">
        <template v-slot:activator="{ props }">
          <v-icon
            color="primary"
            style="cursor: pointer"
            dark
            v-bind="props"
            @click="confirmChanges()"
          >
            mdi-content-save
          </v-icon>
        </template>
        <span>zapisz</span>
      </v-tooltip>
      <v-tooltip anchor="top" v-if="user">
        <template v-slot:activator="{ props }">
          <v-icon
            color="primary"
            style="cursor: pointer"
            dark
            v-bind="props"
            @click="$emit('editCancel')"
            class="ml-4"
          >
            mdi-close-outline
          </v-icon>
        </template>
        <span>anuluj</span>
      </v-tooltip>
    </td>

    <custom-modal
      :flag="flag"
      @close-modal="
        () => {
          flag = false;
          message = '';
        }
      "
    >
      <template v-slot:message>
        <div class="text-justify">{{ message }}</div>
      </template>
    </custom-modal>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import CustomModal from "../layoutComponents/CustomModal.vue";
import api from "../../services/api";
import CompanySelect from "./CompanySelect.vue";

export default {
  components: { CustomModal, CompanySelect },
  name: "UserForm",
  props: ["user"],
  data() {
    return {
      newCompany: false,
      email: "",
      company: "",
      regon: "",
      userId: null,
      companyId: null,
      flag: false,
      message: "",
    };
  },
  methods: {
    ...mapActions("users", ["createUser", "editUser", "createCompany"]),
    setCompany(item) {
      this.companyId = item.id;
    },
    confirmChanges() {
      if (
        !this.companyId &&
        (this.company.trim() == "" || this.regon.trim() == "")
      ) {
        this.message = "Użytkownik musi mieć przypisaną firmę!";
        this.flag = true;
        return false;
      }

      if (!this.companyId) {
        this.createCompany({
          name: this.company.trim(),
          regon: this.regon.trim(),
        })
          .then((res) => {
            this.companyId = res.id;
            if (this.user == null) this.addNewUser();
            else this.changeUser();
          })
          .catch((err) => {
            console.log("Stworzenie nowej firmy nie powiodło się! ", err);
            this.message = "Stworzenie nowej firmy nie powiodło się!";
            this.flag = true;
            return false;
          });
      } else {
        if (this.user == null) this.addNewUser();
        else this.changeUser();
      }
    },
    addNewUser() {
      this.createUser({
        email: this.email.trim(),
        password: Math.floor(Math.random() * 100000000), ///pasword must be provided
        company_users_id: this.companyId,
      })
        .then((res) => {
          this.email = "";
          this.company = "";
          this.regon = "";
          this.userId = null;
          this.companyId = null;
          this.sendPassword(res.id);
        })
        .catch((err) => {
          console.log("Stworzenie nowego użytkownika nie powiodło się! ", err);
          this.message = "Stworzenie nowego użytkownika nie powiodło się!";
          this.flag = true;
          return false;
        });
    },
    changeUser() {
      const changedMail = this.user.email.trim() != this.email.trim();
      let dataToSend = {
        id: this.user.id,
        data: {
          ...this.user,
          email: this.email.trim(),
          company_users_id: this.companyId,
        },
      };
      this.editUser(dataToSend)
        .then((res) => {
          if (changedMail) this.sendPassword(res.id);
          else {
            this.message = "Zedytowano dane użytkownika!";
            this.flag = true;
            this.$emit("editSuccess");
          }
        })
        .catch((err) => {
          console.log("Wystąpił błąd przy edycji użytkownika! ", err);
          this.message = "Wystąpił błąd przy edycji użytkownika!";
          this.flag = true;
          return false;
        });
    },
    sendPassword(userId) {
      api
        .post(`/users/${userId}/get_first_password_for_user/`)
        .then((response) => {
          console.log("Stworzono użytkownika! ", response.data);
          this.message =
            "Stworzono/zedytowano użytkownika i wysłano do niego nowe dane logowania.";
          this.flag = true;
          this.$emit("editSuccess");
          return false;
        })
        .catch((err) => {
          console.log("Wystąpił błąd przy próbie wysłania poświadczeń! ", err);
          this.message = "Wystąpił błąd przy próbie wysłania poświadczeń!";
          this.flag = true;
          return false;
        });
    },
  },
  created() {
    if (this.user) {
      const userCopy = JSON.parse(JSON.stringify(this.user));
      this.email = userCopy.email;
      this.company = userCopy.company_users?.name || "";
      this.regon = userCopy.company_users?.regon || "";
      this.userId = userCopy.id;
      this.companyId = userCopy.company_users_id || null;
    }
  },
};
</script>

<style lang="scss" scoped>
.animated {
  animation-fill-mode: both;
}
</style>
