import api from "@/services/api";

const state = {
  indicators: [],
};

const getters = {
  indicators: (state) => {
    return state.indicators;
  },
};

const actions = {
  getIndicators({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/indicators/`)
        .then((response) => {
          commit("setIndicators", response);
          setTimeout(() => {
            resolve(response);
          }, 300);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setIndicators(state, response) {
    state.indicators = response.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
