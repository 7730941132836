import api from "../../services/api";

const state = {
  currentUser: [],
  isConfirm: false,
};

const getters = {
  currentUser: (state) => {
    return state.currentUser;
  },
  isConfirm: (state) => {
    return state.isConfirm;
  },
};

const actions = {
  getCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`users/current_user/`)
        .then((currentuser) => {
          commit("setVerify", currentuser);
          commit("setCurrentuser", currentuser);
          resolve(currentuser.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

const mutations = {
  setCurrentuser(state, currentuser) {
    state.currentUser = currentuser.data;
  },
  setVerify(state, currentuser) {
    state.isConfirm = currentuser.data.chenge_pass;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
