<template>
  <tr
    :style="
      company
        ? 'background: rgba(var(--v-border-color), var(--v-hover-opacity))'
        : ''
    "
  >
    <td>
      <v-text-field
        :label="company ? 'nazwa' : 'Dodaj nową firmę'"
        variant="underlined"
        v-model="name"
        hide-details="auto"
      />
    </td>
    <td>
      <v-text-field
        label="regon"
        variant="underlined"
        v-model="regon"
        hide-details="auto"
      />
    </td>
    <td v-if="true">
      <v-row class="ma-0 align-center">
        <div>
          <div
            v-for="(kod, id) in editSwd.edited.concat(editSwd.newest)"
            :key="id + 'swd'"
          >
            {{ kod["kod_sw"] }} - {{ kod["oddzial_ow"] }}
          </div>
        </div>
        <v-btn variant="text" @click="editSwd.flag = true" color="primary">
          zarządzaj kodami
        </v-btn>
      </v-row>
    </td>
    <td v-else>
      użytkownicy mogą zarządzać swoimi kodami SWD w zakładce profil
    </td>

    <td class="text-right">
      <v-tooltip anchor="top">
        <template v-slot:activator="{ props }">
          <v-icon
            color="primary"
            style="cursor: pointer"
            dark
            v-bind="props"
            @click="confirmChanges()"
          >
            mdi-content-save
          </v-icon>
        </template>
        <span>zapisz</span>
      </v-tooltip>
      <v-tooltip anchor="top" v-if="company">
        <template v-slot:activator="{ props }">
          <v-icon
            color="primary"
            style="cursor: pointer"
            dark
            v-bind="props"
            @click="$emit('editCancel')"
            class="ml-4"
          >
            mdi-close-outline
          </v-icon>
        </template>
        <span>anuluj</span>
      </v-tooltip>
    </td>

    <custom-modal
      :flag="flag"
      @close-modal="
        () => {
          flag = false;
          message = '';
        }
      "
    >
      <template v-slot:message>
        <div class="text-justify">{{ message }}</div>
      </template>
    </custom-modal>

    <v-dialog v-model="editSwd.flag" persistent>
      <edit-swd :swd="editSwd" @close-modal="closeSwd" @finished="swdChanges" />
    </v-dialog>
  </tr>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CustomModal from "../layoutComponents/CustomModal.vue";
import EditSwd from "./EditSwd.vue";
import api from "../../services/api";

export default {
  components: { CustomModal, EditSwd },
  name: "CompanyForm",
  props: ["company"],
  data() {
    return {
      name: "",
      regon: "",
      flag: false,
      message: "",
      editSwd: {
        flag: false,
        edited: [],
        newest: [],
        deleted: [],
        company: "",
      },
    };
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    ...mapActions("users", [
      "createCompany",
      "editCompany",
      "updateSwd",
      "clearSwd",
    ]),
    confirmChanges() {
      if (this.name.trim() == "" || this.regon.trim() == "") {
        this.message = "Uzupełnij pola nazwa oraz regon!";
        this.flag = true;
        return true;
      }
      const dataToSend = {
        name: this.name.trim(),
        regon: this.regon.trim(),
      };
      if (this.company == null) {
        this.createCompany(dataToSend)
          .then((res) => {
            this.name = "";
            this.regon = "";
            this.message = "Stworzono nową firmę.";
            this.flag = true;
            this.handleSWD(res.id);
          })
          .catch((err) => {
            console.log("Stworzenie nowej firmy nie powiodło się! ", err);
            this.message = "Stworzenie nowej firmy nie powiodło się!";
            this.flag = true;
            return false;
          });
      } else {
        this.editCompany({
          id: this.company.id,
          data: dataToSend,
        })
          .then(() => {
            this.clearSwd(this.company.id);
            this.message = "Zedytowano dane firmy.";
            this.flag = true;
            this.handleSWD(this.company.id);
          })
          .catch((err) => {
            console.log("Edycja nowej firmy nie powiodła się! ", err);
            this.message = "Edycja nowej firmy nie powiodła się!";
            this.flag = true;
            return false;
          });
      }
    },
    swdChanges(edited, newest, deleted) {
      //saves temporary changes to swd in data(before queries)
      this.editSwd.edited = edited;
      this.editSwd.newest = newest;
      this.editSwd.deleted = deleted;
      this.editSwd.flag = false;
    },
    closeSwd() {
      //closes swd modal
      this.editSwd.flag = false;
    },
    handleSWD(companyId) {
      //swd queries
      this.editSwd.edited.forEach((item) => {
        api
          .put(`kodsw/${item.id}/`, { ...item, company_sw_id: companyId })
          .then((res) =>
            this.updateSwd({ data: res.data, companyId: companyId })
          )
          .catch((err) => {
            console.log(`Error at ${item.kod_sw}: ${err}`);
          });
      });
      this.editSwd.newest.forEach((item) => {
        api
          .post(`kodsw/`, { ...item, company_sw_id: companyId })
          .then((res) =>
            this.updateSwd({ data: res.data, companyId: companyId })
          )
          .catch((err) => {
            console.log(`Error at ${item.kod_sw}: ${err}`);
          });
      });
      this.editSwd.deleted.forEach((id) => {
        api.delete(`kodsw/${id}`).catch((err) => {
          console.log(`Error at id ${id}: ${err}`);
        });
      });
      this.editSwd = {
        flag: false,
        edited: [],
        newest: [],
        deleted: [],
        company: "",
      };
      this.$emit("editSuccess");
    },
  },
  created() {
    if (this.company) {
      const companyCopy = JSON.parse(JSON.stringify(this.company));
      this.name = companyCopy.name;
      this.regon = companyCopy.regon;
      this.editSwd.edited = companyCopy.kodsw;
      this.editSwd.company = companyCopy.name;
    }
  },
};
</script>

<style lang="scss" scoped>
.animated {
  animation-fill-mode: both;
}
</style>
