<template>
  <div>
    <v-container fluid style="padding-top: 5%">
      <v-row align="center" justify="center" dense>
        <v-col>
          <v-card class="ma-auto pa-4" max-width="500" max-height="650">
            <v-card-title class="align-center justify-center">
              <v-img
                @click="$router.push('/')"
                :src="require('../../assets/logo_bez_tla.png')"
                class="my-3"
                contain
                height="115"
                style="cursor: pointer"
              />
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="pb-10">
                      <div style="text-align: center">
                        <h1 class="font-weight-light">Podaj nowe hasło</h1>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pa-0">
                      <v-text-field
                        v-model="credentials.password"
                        label="Hasło"
                        outlined
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[rules.required, rules.min]"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pa-0">
                      <v-text-field
                        v-model="credentials.passwordRepeated"
                        :append-icon="
                          showPasswordRepeated ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="showPasswordRepeated ? 'text' : 'password'"
                        :rules="[rules.required, rules.equal]"
                        label="Powtórz hasło"
                        @click:append="
                          showPasswordRepeated = !showPasswordRepeated
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                rounded="pill"
                color="white"
                style="width: 80%; background: #17728d"
                @click="changePassword()"
                :disabled="!isButtonDisabled"
              >
                Zapisz</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <custom-snackbar :snackbar="snackbar" @clear="updateSnackbar()" />
    </v-container>
  </div>
</template>

<script>
import api from "../../services/api";
import CustomSnackbar from "../layoutComponents/CustomSnackbar.vue";

export default {
  components: { CustomSnackbar },
  name: "ResetPassword",
  data() {
    return {
      credentials: {
        password: "",
        passwordRepeated: "",
      },
      snackbar: {
        open: false,
        text: "",
        color: "",
      },

      showPassword: false,
      showPasswordRepeated: false,
      rules: {
        required: (value) => !!value || "Pole wymagane.",
        min: (v) => v.length >= 8 || "Hasło musi posiadać conajmniej 8 znaków.",
        equal: (text) =>
          text === this.credentials.password || "Hasła muszą być identyczne.",
      },
    };
  },
  computed: {
    isButtonDisabled() {
      return (
        this.credentials.password &&
        this.credentials.passwordRepeated &&
        this.credentials.password === this.credentials.passwordRepeated &&
        this.credentials.password.length >= 8 &&
        this.credentials.passwordRepeated.length >= 8
      );
    },
  },
  methods: {
    updateSnackbar(
      msg = {
        open: false,
        text: "",
        color: "",
      }
    ) {
      this.snackbar = msg;
    },

    changePassword() {
      this.$emit("loading", true);
      let urlArray = this.$route.path.split("/");
      let dataToSend = {
        password: this.credentials.password,
        repeat_password: this.credentials.passwordRepeated,
        uidb64: urlArray[urlArray.length - 3],
        token: urlArray[urlArray.length - 2],
      };
      api
        .post(`/reset/:uidb64/:token`, dataToSend)
        .then((response) => {
          if (response.status === 200) {
            this.updateSnackbar({
              open: true,
              color: "success",
              text: response?.data,
            });
          }
          setTimeout(() => {
            this.$router.push("/#");
          }, 1500);
        })
        .catch((response) => {
          this.updateSnackbar({
            open: true,
            color: "error",
            text: response?.data,
          });
        });
    },
  },
};
</script>

<style></style>
