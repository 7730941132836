<template>
  <v-card flat>
    <v-card-text>
      <v-table density="compact">
        <thead>
          <tr>
            <th class="text-left">Kod świadczeniodawcy</th>
            <th class="text-left">Oddział wojewódzki</th>
            <th class="text-right">Opcje</th>
          </tr>
        </thead>
        <tbody class="pb-6">
          <swd-form :swd="null" />
          <template v-for="sw in kodsw">
            <tr :key="sw.id" v-if="!editedRecord || editedRecord.id != sw.id">
              <td>{{ sw.kod_sw }}</td>
              <td>{{ sw.oddzial_ow }}</td>
              <td class="text-right">
                <v-tooltip anchor="top">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      color="primary"
                      style="cursor: pointer"
                      dark
                      v-bind="props"
                      @click="editedRecord = sw"
                    >
                      mdi-square-edit-outline
                    </v-icon>
                  </template>
                  <span>edytuj</span>
                </v-tooltip>
                <v-tooltip anchor="top">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      color="primary"
                      style="cursor: pointer"
                      dark
                      v-bind="props"
                      @click="deleteSW(sw)"
                      class="ml-4"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </template>
                  <span>usuń</span>
                </v-tooltip>
              </td>
            </tr>
            <swd-form
              :key="sw.id"
              :swd="editedRecord"
              v-else
              @loading="setLoading"
              @finish-edit="leaveEdit"
              @update-snackbar="updateSnackbar"
            />
          </template>
        </tbody>
      </v-table>
    </v-card-text>
    <custom-snackbar :snackbar="snackbar" @clear="updateSnackbar" />
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CustomSnackbar from "../layoutComponents/CustomSnackbar.vue";
import SwdForm from "./SwdForm.vue";

export default {
  components: { SwdForm, CustomSnackbar },
  name: "ManageSwd",
  data() {
    return {
      editedRecord: null,
      snackbar: {
        open: false,
        text: "",
        color: "",
      },
    };
  },
  computed: {
    ...mapState({
      kodsw: (state) => state.kodsw.kodsw,
    }),
  },
  methods: {
    ...mapActions("kodsw", ["getMyKodsw", "removeKodsw"]),
    deleteSW(element) {
      this.setLoading(true);
      this.removeKodsw(element.id)
        .then(() => {
          this.updateSnackbar({ color: "success", text: "Usunięto kod." });
          this.setLoading(false);
        })
        .catch((err) => {
          this.updateSnackbar({
            color: "error",
            text: "Wystąpił błąd przy usuwaniu.",
          });
          this.setLoading(false);
          console.log(err.status, " ---- ", err.data);
        });
    },
    setLoading(flag) {
      this.$emit("loading", flag);
    },
    leaveEdit() {
      this.editedRecord = null;
    },
    updateSnackbar(
      msg = {
        open: false,
        text: "",
        color: "",
      }
    ) {
      this.snackbar = msg;
    },
  },
  created() {
    this.getMyKodsw();
  },
};
</script>

<style></style>
