<template>
  <v-card flat>
    <v-card-text>
      <v-text-field
        class="mt-5"
        v-model="credentials.newPassword"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="show2 ? 'text' : 'password'"
        name="input-10-1"
        label="Nowe hasło"
        hint="Conajmniej 8 znaków"
        counter
        @click:append="show2 = !show2"
      />
      <v-text-field
        v-model="credentials.newPasswordRepeat"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min, rules.equal]"
        :type="show1 ? 'text' : 'password'"
        name="input-10-1"
        label="Powtórz hasło"
        hint="Conajmniej 8 znaków"
        counter
        @click:append="show1 = !show1"
      />
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        rounded="pill"
        color="white"
        style="background: #17728d"
        @click="changePassword()"
      >
        Zapisz zmiany
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "../../services/api";
export default {
  name: "ChangePassword",
  data() {
    return {
      credentials: {
        newPassword: "",
        newPasswordRepeat: "",
      },
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Wymagane.",
        min: (v) => v.length >= 8 || "Conajmniej 8 znaków",
        equal: (text) =>
          text === this.credentials.newPassword || "Hasła muszą być identyczne",
      },
    };
  },
  methods: {
    changePassword() {
      this.$emit("loading", true);
      let dataToSend = {
        pw1: this.credentials.newPassword,
        pw2: this.credentials.newPasswordRepeat,
      };
      api
        .post(`/users/change_password/`, dataToSend)
        .then((response) => {
          setTimeout(() => {
            this.$router.push("/import");
          }, 1500);
          this.$emit("modal", true, response.data);
          this.$emit("loading", false);
          this.$emit("success", true, "Zmiany zostały zapisane pomyślnie.");
        })
        .catch((err) => {
          this.$emit("modal", true, err.data);
          this.$emit("loading", false);
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
