<template>
  <v-dialog v-model="isTemplateModalVisible">
    <v-card>
      <v-card-text class="text-center"
        >Obowiązek stosowania standardu rachunku kosztów zgodnie z
        Rozporządzeniem Ministra Zdrowia z dnia 26 października 2020 r.<br />w
        sprawie zaleceń dotyczących standardu rachunku kosztów u
        świadczeniodawców</v-card-text
      >
      <div class="template-icons-container">
        <div
          class="template-icon"
          @click="getTemplateTempUrl('szablon_z_SRK.xlsx')"
        >
          <img
            :src="require('../assets/ikonka_pobierz_szablon_pliku_cropped.svg')"
            style="height: 40px"
            class="mt-2"
          /><br />
          <p class="font-weight-regular" style="font-size: 12px">
            Mam obowiązek
          </p>
        </div>
        <div
          class="template-icon"
          @click="getTemplateTempUrl('szablon_bez_SRK.xlsx')"
        >
          <img
            :src="require('../assets/ikonka_pobierz_szablon_pliku_cropped.svg')"
            style="height: 40px"
            class="mt-2"
          /><br />
          <p class="font-weight-regular" style="font-size: 12px">
            Nie mam obowiązku
          </p>
        </div>
      </div>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isInstructionModalVisibile">
    <v-card>
      <v-card-text class="text-center"
        >Obowiązek stosowania standardu rachunku kosztów zgodnie z
        Rozporządzeniem Ministra Zdrowia z dnia 26 października 2020 r.<br />w
        sprawie zaleceń dotyczących standardu rachunku kosztów u
        świadczeniodawców</v-card-text
      >
      <div class="template-icons-container">
        <div
          class="template-icon"
          @click="getTemplateTempUrl('instrukcja_z_SRK.pdf')"
        >
          <img
            :src="require('../assets/ikonka_pobierz_szablon_pliku_cropped.svg')"
            style="height: 40px"
            class="mt-2"
          /><br />
          <p class="font-weight-regular" style="font-size: 12px">
            Mam obowiązek
          </p>
        </div>
        <div
          class="template-icon"
          @click="getTemplateTempUrl('instrukcja_bez_SRK.pdf')"
        >
          <img
            :src="require('../assets/ikonka_pobierz_szablon_pliku_cropped.svg')"
            style="height: 40px"
            class="mt-2"
          /><br />
          <p class="font-weight-regular" style="font-size: 12px">
            Nie mam obowiązku
          </p>
        </div>
      </div>
    </v-card>
  </v-dialog>

  <div class="mb-15">
    <h5 class="text-center font-weight-regular">
      Monitorowanie wartości wynagrodzeń pracowników<br />
      jako najistotniejszego kosztu związanego z działalnością podmiotów
      leczniczych
    </h5>
    <h2 class="text-center ma-5 font-weight-regular">
      appRKP (Raport Kadrowo-Płacowy)
    </h2>
    <div class="text-center ma-auto">
      <v-btn
        class="px-10 shadow gradientAotmit"
        text
        @click="goTo('/logowanie')"
      >
        Logowanie
      </v-btn>
    </div>
    <div class="text-center ma-auto mx-auto gridDisp mt-10">
      <div
        class="blockCard item1"
        style="justify-self: end"
        @click="openTemplateModal()"
      >
        <img
          :src="require('../assets/ikonka_pobierz_szablon_pliku_cropped.svg')"
          style="height: 40px"
          class="mt-2"
        /><br />
        <p class="font-weight-regular" style="font-size: 12px">
          pobierz szablon pliku
        </p>
      </div>
      <div class="blockCard item2" @click="openInstructionModal()">
        <img
          :src="require('../assets/ikonka_pobierz_instrukcję_cropped.svg')"
          style="height: 40px"
          class="mt-2"
        /><br />
        <p class="font-weight-regular" style="font-size: 12px">
          pobierz instrukcję
        </p>
      </div>
      <div
        class="blockCard item3"
        @click="
          getTemplateTemp('instrukcja_logowania_Raport_Kadrowo_Placowy.pdf')
        "
      >
        <img
          :src="require('../assets/ikonka_instr_logowania.svg')"
          style="height: 40px"
          class="mt-2"
        /><br />
        <p class="font-weight-regular" style="font-size: 12px">
          instrukcja logowania
        </p>
      </div>
      <div class="blockCard item5" @click="openPresentation">
        <img
          :src="require('../assets/presentation_icon.svg')"
          style="height: 40px"
          class="mt-2"
        /><br />
        <p class="font-weight-regular" style="font-size: 12px">materiały</p>
      </div>
      <div class="blockCard item4" @click="showBugModal()">
        <img
          :src="require('../assets/ikonka_formularz.svg')"
          style="height: 40px"
          class="mt-2"
        /><br />
        <p class="font-weight-regular" style="font-size: 12px">zgłoś błędy</p>
      </div>
    </div>
    <div class="my-12 text-center">
      <v-btn variant="text" color="primary" @click="getTemplateTemp('faq.pdf')">
        <v-icon class="mr-1">mdi-chat-question-outline</v-icon>
        <span>Najczęściej zadawane pytania (pobierz)</span>
      </v-btn>
    </div>

    <div
      class="text-center ma-auto mt-16 mt-xs-0 cardsCenter"
      style="width: 40%"
    >
      <v-row>
        <v-col>
          <img
            :src="require('../assets/ikonka_wprowadzanie_danych-cropped.svg')"
            style="height: 50px"
            class="mt-2"
          />
          <h4 class="text-center ma-5 font-weight-regular">
            Wprowadzanie danych
          </h4>
          <h5 class="text-center ma-10 ma-xs-0 font-weight-regular">
            Tutaj wprowadzisz i&nbsp;zatwierdzisz dane płacowo-kadrowe dotyczące
            pracowników zatrudnionych w&nbsp;Twoim podmiocie
          </h5>
        </v-col>
        <v-col>
          <img
            :src="require('../assets/ikonka_raporty-cropped.svg')"
            style="height: 50px"
            class="mt-2"
          />
          <h4 class="text-center ma-5 font-weight-regular">Raporty</h4>
          <h5 class="text-center ma-10 mx-15 font-weight-regular">
            Tutaj wygenerujesz w&nbsp;formie tabel i&nbsp;wykresów według
            wybranych przez siebie kryteriów
          </h5>
        </v-col>
      </v-row>
    </div>
    <div class="text-center ma-auto">
      <v-btn
        class="px-10 shadowAdv gradientAotmit"
        style="
          width: 50%;
          text-transform: none
          font-weight: bold;
          cursor: default;
        "
        ><span style="white-space: normal; height: min-content"
          >Korzyści z użytkowania aplikacji</span
        >
      </v-btn>
    </div>
    <v-container class="">
      <v-row>
        <v-col xs="12" md="6" lg="3">
          <card-land :message="message[0]" :num="1" color="#1f7690"></card-land>
        </v-col>
        <v-col xs="12" md="6" lg="3">
          <card-land
            :message="message[1]"
            :num="2"
            color="#3593a8;"
          ></card-land>
        </v-col>
        <v-col xs="12" md="6" lg="3">
          <card-land
            :message="message[2]"
            :num="3"
            color="#4eb1be;"
          ></card-land>
        </v-col>
        <v-col xs="12" md="6" lg="3">
          <card-land :message="message[3]" :num="4" color="#6acfd3"></card-land>
        </v-col>
        <!-- <v-col>
          <card-land
            :message="message[4]"
            :num="5"
            color="#6acfd3;"
          ></card-land>
        </v-col> -->
      </v-row>
    </v-container>
    <v-dialog v-model="flagTrue">
      <v-card>
        <v-card-text class="text-center">
          <div style="text-align: center">
            <p>
              Szanowni Państwo,
              <br />
              <br />

              Zbiórka danych kadrowo-płacowych za
              <strong> luty 2024 </strong> została <strong> zakończona.</strong>
              <br />
              Bardzo dziękujemy wszystkim uczestnikom zbiórki za przesłanie
              danych.
              <br />
            </p>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="primary" text @click="flagTrue = false">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <custom-modal :flag="flag" @close-modal="closePresentation">
      <template v-slot:message>
        <div>
          <v-row class="text-left">
            <div class="template-icons-container" v-if="showSrkBtns">
              <div class="template-icon" @click="withSrk(this.materialList)">
                <img
                  :src="
                    require('../assets/ikonka_pobierz_szablon_pliku_cropped.svg')
                  "
                  style="height: 40px"
                  class="mt-2"
                /><br />
                <p class="font-weight-regular" style="font-size: 12px">Z SRK</p>
              </div>
              <div class="template-icon" @click="withoutSrk(this.materialList)">
                <img
                  :src="
                    require('../assets/ikonka_pobierz_szablon_pliku_cropped.svg')
                  "
                  style="height: 40px"
                  class="mt-2"
                /><br />
                <p class="font-weight-regular" style="font-size: 12px">
                  Bez SRK
                </p>
              </div>
            </div>
            <v-col v-if="this.showSrkBtns === false">
              <p><strong>Naciśnij na wybrany plik, aby go pobrać.</strong></p>
              <v-list v-if="listOfMaterials.length">
                <v-list-item
                  v-for="(item, idx) in listOfMaterials"
                  :key="idx"
                  @click="getTemplateTempMaterials(item.file.split('/').pop())"
                  >{{ item.name }}</v-list-item
                >
                <v-list-item
                  @click="getTemplateTempMaterials('6_excel_online.mp4')"
                >
                  Excel Online
                </v-list-item>
                <v-list-item
                  v-if="this.srk === 'without'"
                  @click="
                    getTemplateTempMaterials('Szkolenie_podmiotow_bez_SRK.mp4')
                  "
                >
                  Szkolenie podmiotów bez SRK
                </v-list-item>
                <v-list-item
                  v-if="this.srk === 'with'"
                  @click="
                    getTemplateTempMaterials('Szkolenie_podmiotow_z_SRK.mp4')
                  "
                >
                  Szkolenie podmiotów z SRK
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </template>
    </custom-modal>
    <custom-modal :flag="bugModal" @close-modal="closeBugModal">
      <template v-slot:message>
        <div>
          <v-row class="text-left">
            <div>
              Problemy techniczne, wszelkie błędy w aplikacji RKP proszę
              zgłaszać na maila
              <strong>apprkp@aotm.gov.pl</strong>.
            </div>
          </v-row>
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import router from "../router";
import CardLand from "../components/landingPageCards/CardLand.vue";
import CustomModal from "../components/layoutComponents/CustomModal.vue";

export default {
  name: "LandingPage",
  components: {
    CardLand,
    CustomModal,
  },
  data() {
    return {
      message: [
        "Automatyzacja procesu przekazywania danych finansowo-kadrowych - wprowadzenie danych przez import udostępnionego szablonu plików",
        "Monitorowanie dynamiki zmian kosztów osobowych pracowników w&nbsppodziale na kategorię personelu i&nbspformę zatrudnienia",
        "Reprezentatywna próba danych pozwoli na rzetelne oszacowanie taryf",
        "Prezentacja wyników w postaci zbiorczych raportów&nbspi analiz przekrojowych ",
        "Docelowa możliwość benchmarkingui&nbspna podstawie zanonimizowanych danych innych podmiotów w podziale na kategorie personelu i&nbspwojewództwa ",
      ],
      flag: false,
      isTemplateModalVisible: false,
      isInstructionModalVisibile: false,
      flagTrue: true,
      showSrkBtns: false,
      listOfMaterials: [],
      srk: "",
      bugModal: false,
    };
  },
  computed: {
    ...mapState({
      materialList: (state) => state.materials.materialList.results,
    }),
  },
  methods: {
    ...mapActions("materials", ["getMaterialList"]),
    goTo(link) {
      router.push(link);
    },
    showBugModal() {
      this.bugModal = true;
    },
    closeBugModal() {
      this.bugModal = false;
    },
    async getTemplateTemp(name) {
      try {
        let anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = process.env.VUE_APP_API_ENDPOINT2 + `media/${name}`;
        anchor.download = name;
        document.body.appendChild(anchor);
        await anchor.click();
      } catch (err) {
        console.log("error");
      }
    },
    async getTemplateTempUrl(name) {
      try {
        let anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href =
          process.env.VUE_APP_API_ENDPOINT2 + `media/materials/${name}`;
        anchor.download = name;
        document.body.appendChild(anchor);
        await anchor.click();
      } catch (err) {
        console.log("error");
      }
    },
    async getTemplateTempMaterials(name) {
      try {
        let anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href =
          process.env.VUE_APP_API_ENDPOINT2 + `media/materials/${name}`;
        anchor.download = name;
        document.body.appendChild(anchor);
        await anchor.click();
      } catch (err) {
        console.log("error");
      }
    },
    openPresentation() {
      this.flag = true;
      this.showSrkBtns = true;
    },
    closePresentation() {
      this.flag = false;
    },
    withSrk(files) {
      this.showSrkBtns = false;
      this.srk = "with";

      const newList = files?.filter((file) => file.isSRK === true);
      this.listOfMaterials = newList;
    },
    withoutSrk(files) {
      this.showSrkBtns = false;
      this.srk = "without";

      const newList = files.filter((file) => file.isSRK === false);
      this.listOfMaterials = newList;
    },
    openTemplateModal() {
      this.isTemplateModalVisible = true;
    },
    openInstructionModal() {
      this.isInstructionModalVisibile = true;
    },
    closeModal() {
      this.flagTrue = false;
    },
  },
  created() {
    this.getMaterialList(this.materialList);
  },
};
</script>

<style scoped lang="scss">
.text-center {
  text-align: justify;
  display: flex;
  justify-content: center;
}
.shadow {
  box-shadow: 0px 0px 5px #afafaf;
  border: 3px solid white;
  border-radius: 10px;
}
.shadowAdv {
  @extend .shadow;
  box-shadow: 0px 2px 4px #afafaf;
}
.gridDisp {
  width: 675px;
  margin: auto;
  display: flex; /* Change to display: flex */
  justify-content: space-between; /* Add this line to create space between icons */
}

.blockCard {
  /* Remove display: inline-block */
  height: 90px;
  border-radius: 12px;
  box-shadow: 0px 0px 5px #888888;
  background-color: white;
  cursor: pointer;
  width: 150px;
  flex-grow: 1; /* Add this line to make them fill the available space evenly */
  margin-right: 10px; /* Add margin-right for the gap */
}
.blockCard,
.newTemplate {
  height: 90px;
  border-radius: 12px;
  box-shadow: 0px 0px 5px #888888;
  background-color: white;
  cursor: pointer;
  width: 150px;
}
.newTemplate {
  box-shadow: 0px 0px 6px #971212;
  width: 320px;
  display: grid;
  grid-template-columns: 70px auto;
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.cardAdv {
  min-height: 240px;
  border-radius: 12px;
  box-shadow: 0px 0px 5px #888888;
}

@media only screen and (max-width: 600px) {
  .marginCards {
    margin: 2px 3px 10px 3px !important;
  }
  .shadowAdv {
    width: 95% !important;
  }

  .cardsCenter {
    width: 95% !important;
  }
  .cardAdv {
    min-height: 260px;
  }
  .item1 {
    grid-area: ins;
  }
  .item2 {
    grid-area: pdf;
  }
  .item3 {
    grid-area: log;
  }
  .item4 {
    grid-area: form;
  }
  .gridDisp {
    // width: 22%;
    display: grid;
    grid-template-areas:
      "ins pdf"
      "log form" !important;
    gap: 10px;
    margin: auto;
    grid-template-columns: none;
    width: 80%;
  }
}
.marginCards {
  margin: 40px 320px 0px 320px;
}

.gradientAotmit {
  color: white;
  background: #207790;
  background: -webkit-linear-gradient(left, #207790, #6ad0d3);
  background: -moz-linear-gradient(left, #207790, #6ad0d3);
  background: linear-gradient(to right, #207790, #6ad0d3);
}
.template-icons-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically (if needed) */
  flex-wrap: wrap; /* Allow items to wrap */
}

.template-icon {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  margin: 20px; /* Increase margin as needed */
}

.template-icon:hover {
  transform: scale(1.1); /* Slightly enlarges the icon on hover */
}
</style>
