<template>
  <v-dialog v-model="show">
    <v-card>
      <v-card-text
        style="line-height: 1.8em"
        :class="color ? `text-${color}` : ''"
        ><slot name="message"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CustomModalWithoutClose",
  props: {
    flag: Boolean,
    color: String,
  },
  data() {
    return {
      dialogInfo: false,
    };
  },
  computed: {
    show: {
      get: function () {
        return this.flag;
      },
      set: function (newValue) {
        this.show = newValue;
      },
    },
  },
};
</script>

<style></style>
