<template>
  <div>
    <div class="mx-md-16 px-md-12 mx-5">
      <v-card-title class="justify-center mt-5 text-primary"
        >Ogólna klauzula informacyjna</v-card-title
      >
      <v-card-text>
        <div className="text-justify">
          Zgodnie z&nbsp;rozporządzeniem Parlamentu Europejskiego i&nbsp;Rady
          (UE) 2016/679 z&nbsp;dnia 27 kwietnia 2016&nbsp;r. w&nbsp;sprawie
          ochrony osób fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych
          osobowych i&nbsp;w&nbsp;sprawie swobodnego przepływu takich danych
          oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
          o&nbsp;ochronie danych) (Dz. Urz. UE L 119 z&nbsp;4.05.2016, str. 1,
          z&nbsp;późn. zm.), zwanym dalej „RODO”, informujemy, że:
          <br /><br />
          Administratorem Państwa danych osobowych jest Agencja Oceny
          Technologii Medycznych i&nbsp;taryfikacji z&nbsp;siedzibą
          w&nbsp;Warszawie (00-032) przy ul. Przeskok 2, z&nbsp;którym można
          kontaktować się listownie lub poprzez adres e-mail:
          sekretariat@aotm.gov.pl
          <br /><br />
          Administrator wyznaczył inspektora ochrony danych, z&nbsp;którym mogą
          się Państwo kontaktować poprzez adres e-mail: iod@aotm.gov.pl lub
          listownie na&nbsp;adres siedziby administratora. z&nbsp;inspektorem
          ochrony danych można kontaktować się we wszystkich sprawach
          dotyczących przetwarzania danych osobowych oraz korzystania
          z&nbsp;praw związanych z&nbsp;przetwarzaniem danych.
          <br /><br />
          Państwa dane osobowe mogą być przetwarzane, jeżeli jest to niezbędne
          do wypełnienia obowiązku prawnego ciążącego na&nbsp;administratorze,
          a&nbsp;w&nbsp;szczególności do załatwienia sprawy zgodnie
          z&nbsp;obowiązującymi przepisami prawa oraz wykonywania przez
          administratora zadań realizowanych w&nbsp;interesie publicznym lub
          sprawowania władzy publicznej powierzonej administratorowi,
          a&nbsp;także w&nbsp;innych przypadkach określonych
          w&nbsp;obowiązujących przepisach, zgodnie z&nbsp;art. 6 ust. 1 lit. e
          rozporządzenia Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679
          z&nbsp;dnia 27 kwietnia 2016 r. w&nbsp;sprawie ochrony osób fizycznych
          w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych
          i&nbsp;w&nbsp;sprawie swobodnego przepływu takich danych oraz
          uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO”. Szczegółowa
          podstawa przetwarzania Państwa danych osobowych zależy od treści
          wystąpienia i&nbsp;charakteru sprawy.
          <br /><br />
          Podanie danych, z&nbsp;zastrzeżeniem szczególnych przepisów prawa,
          jest dobrowolne, lecz niezbędne do prowadzenia sprawy,
          w&nbsp;szczególności w&nbsp;trybie właściwych przepisów.
          <br /><br />
          Państwa dane osobowe mogą być przekazane wyłącznie podmiotom, którym
          administrator powierzył przetwarzanie danych osobowych, a&nbsp;także
          podmiotom uprawnionym do ich otrzymania na&nbsp;podstawie przepisów
          prawa.
          <br /><br />
          Państwa dane osobowe będą przechowywane przez okres niezbędny do
          realizacji celów przetwarzania, nie&nbsp;krócej niż okres wskazany
          w&nbsp;przepisach o&nbsp;archiwizacji tj. ustawie z&nbsp;dnia 14 lipca
          1983&nbsp;r. o&nbsp;narodowym zasobie archiwalnym i&nbsp;archiwach
          (Dz. U. z&nbsp;2020 r. poz. 164, z&nbsp;późn. zm.).
          <br /><br />
          Przysługuje Państwu prawo dostępu do swoich danych oraz otrzymania ich
          kopii, prawo ich sprostowania (poprawiania), a&nbsp;także prawo
          usunięcia danych, przenoszenia danych, ograniczenia przetwarzania oraz
          sprzeciwu wobec przetwarzania danych osobowych.
          <br /><br />
          Mają Państwo także prawo cofnięcia zgody w&nbsp;dowolnym momencie,
          jeżeli Państwa dane są przetwarzane na&nbsp;podstawie zgody. Wycofanie
          zgody nie&nbsp;wpływa na&nbsp;zgodność z&nbsp;prawem przetwarzania,
          którego dokonano przed jej cofnięciem.
          <br /><br />
          Mają Państwo prawo wniesienia skargi do organu nadzorczego, tj.
          Prezesa Urzędu Ochrony Danych Osobowych (na&nbsp;adres: ul. Stawki 2,
          00-193 Warszawa). Mogą to Państwo zrobić, jeśli uznają, że
          przetwarzamy dane osobowe z&nbsp;naruszeniem przepisów prawa.
          <br /><br />
          Nie&nbsp;przetwarzamy Państwa danych w&nbsp;sposób zautomatyzowany,
          w&nbsp;tym w&nbsp;formie profilowania. nie&nbsp;przekazujemy Państwa
          danych do państw trzecich lub organizacji międzynarodowych.
        </div>
      </v-card-text>
      <v-card-title class="justify-center mt-5 text-primary"
        >Polityka Prywatności</v-card-title
      >
      <v-card-text>
        <div className="text-justify">
          <h4>Polityka cookies</h4>
          Strona portalu wykorzystuje pliki cookie celem zapewnienia świadczenia
          usług na&nbsp;najwyższym poziomie. Używamy tej technologii, aby móc
          dostosować nasz serwis do indywidualnych potrzeb użytkowników. Możecie
          Państwo w&nbsp;każdej chwili zmienić ustawienia przechowywania
          i&nbsp;uzyskania dostępu do cookies w&nbsp;ustawieniach Państwa
          przeglądarki internetowej. Więcej informacji poniżej:
          <br /><br />
          <strong>Czym są pliki cookies? </strong><br />
          “Cookies” (Ciasteczka) są to pojedyncze, małe pliki tekstowe
          zapisywane na&nbsp;Państwa komputerze. Zawierają one bardzo krótkie
          informacje tekstowe pozwalające na&nbsp;odczytanie zawartych
          w&nbsp;nich informacji przez stronę, która je utworzyła. Pliki cookies
          nie&nbsp;mogą gromadzić żadnych Państwa danych, jeśli te dane
          nie&nbsp;zostaną wpisane przez daną stronę internetową. Przy wysyłaniu
          cookies nie&nbsp;jest naruszana Państwa prywatność.
          <br /><br />
          <strong>Jakich plików cookies używamy? </strong><br />
          <ul>
            <li>
              pliki sesyjne, które pozostają na&nbsp;urządzeniu użytkownika, aż
              do opuszczenia przez niego strony internetowej lub wyłączenia
              przeglądarki internetowej,
            </li>
            <li>
              pliki stałe, które pozostają na&nbsp;urządzeniu użytkownika przez
              określony czas albo do momentu ich ręcznego usunięcia przez
              użytkownika.
            </li>
          </ul>
          <br />
          <strong>Jak włączyć/wyłączyć obsługę cookies? </strong><br />
          Przeglądarki internetowe pozwalają użytkownikom kontrolować cookies
          pod kątem ich gromadzenia i&nbsp;usuwania. Większość przeglądarek jest
          domyślnie ustawionych na&nbsp;automatyczne przyjmowanie ciasteczek.
          Użytkownicy mogą jednak samodzielnie określać warunki przechowywania
          lub uzyskiwania dostępu do danych zapisanych w&nbsp;plikach cookies
          poprzez zmianę ustawień przeglądarki internetowej. Więcej informacji
          na&nbsp;temat zmiany ustawień w&nbsp;przeglądarki można znaleźć
          w&nbsp;pomocy do przeglądarki lub/i&nbsp;na&nbsp;stronie internetowej
          producenta przeglądarki.
          <br /><br />
          <v-card-title>
            Zmiana ustawień cookies w&nbsp;najbardziej popularnych
            wyszukiwarkach:
          </v-card-title>
          <strong>Google Chrome </strong><br />
          Menu > Ustawienia > Pokaż ustawienia zaawansowane > Prywatność >
          Ustawienia treści > Pliki cookies – należy wybrać odpowiednią opcję.
          <br /><br />
          <strong>Internet Explorer </strong><br />
          Menu > Narzędzia > Opcje internetowe > Prywatność – należy wybrać
          odpowiednią opcję.
          <br /><br />
          <strong>Mozilla Firefox </strong><br />
          Menu > Opcje > Prywatność > Historia – należy wybrać odpowiednią
          opcję.
          <br /><br />
          <strong>Opera </strong><br />
          Menu > Preferencje > Zaawansowane > Ciasteczka – należy wybrać
          odpowiednią opcję.
          <br /><br />
          <strong>Safari </strong><br />
          Menu > Preferencje > Prywatność > Pliki cookies – należy wybrać
          odpowiednią opcję.
        </div>
        <br />
        <div className="text-right">
          <v-btn color="primary" variant="outlined" @click="$router.push('/')"
            >Powrót</v-btn
          >
        </div>
      </v-card-text>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style></style>
