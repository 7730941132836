<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="my-5">
          <v-toolbar dense floating style="width: 30%" class="pr-0">
            <v-text-field
              v-model="searchField"
              hide-details
              prepend-icon="mdi-magnify"
              single-line
              label="Wyszukaj"
              @input="filter()"
              :hint="numOfFounded"
            />
          </v-toolbar>
          <br />
          Znaleziono {{ companies.count }} rekordów
        </div>

        <v-table density="compact">
          <thead>
            <tr>
              <th class="text-left">Nazwa firmy</th>
              <th class="text-left">Regon</th>
              <th class="text-left">Kody SWD</th>
              <th class="text-right">Opcje</th>
            </tr>
          </thead>
          <tbody class="pb-6">
            <company-form :company="null" />
            <template v-for="company in companies.results">
              <tr
                v-if="!editedRecord || editedRecord.id != company.id"
                :key="company.id"
              >
                <td>{{ company.name }}</td>
                <td>{{ company.regon }}</td>
                <td>
                  <span v-for="(kod, id) in company.kodsw" :key="id + 'swd'">
                    {{ kod["kod_sw"] }} - {{ kod["oddzial_ow"] }}<br />
                  </span>
                </td>
                <td class="text-right">
                  <v-tooltip anchor="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        color="primary"
                        style="cursor: pointer"
                        dark
                        v-bind="props"
                        @click="editedRecord = company"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>edytuj</span>
                  </v-tooltip>
                </td>
              </tr>
              <company-form
                v-else
                :key="company.id"
                :company="editedRecord"
                @edit-success="finishedEdit"
                @edit-cancel="finishedEdit"
              />
            </template>
          </tbody>
        </v-table>
        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col :cols="calculateCols(companies.count)">
                <v-container class="max-width">
                  <v-pagination
                    v-model="page"
                    :length="pageNum(companies.count)"
                    class="my-4"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container></div></v-card-text
    ></v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CompanyForm from "./CompanyForm.vue";

export default {
  name: "ManageCompanies",
  data() {
    return {
      page: 1,
      size: 20,
      editedRecord: null,
      searchField: "",
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.users.companies,
    }),
    numOfFounded() {
      return `Znaleziono ${this.companies.count}`;
    },
  },
  watch: {
    page(newVal) {
      this.filter(newVal);
    },
  },
  methods: {
    ...mapActions("users", ["getCompanies"]),
    pageNum(numOfPages) {
      if (numOfPages) return Math.ceil(numOfPages / this.size);
    },
    calculateCols(numOfPages) {
      return Math.ceil(numOfPages / this.size) < 4
        ? Math.ceil(numOfPages / this.size)
        : 4;
    },
    filter(page = null) {
      let params = { size: this.size, search: this.searchField };
      page ? (params["page"] = this.page) : (this.page = 1);
      this.getCompanies({ params }).catch((error) => {
        console.log(error);
      });
    },
    finishedEdit() {
      this.editedRecord = null;
    },
  },
  created() {
    let params = { size: this.size };
    this.getCompanies({ params }).catch((error) => {
      console.log(error);
    });
  },
  components: {
    CompanyForm,
  },
};
</script>

<style></style>
