<template>
  <div>
    <v-card elevation="1" class="ma-16">
      <v-progress-linear
        v-if="loading"
        class="position-absolute"
        style="z-index: 1"
        color="primary"
        height="3"
        indeterminate
      />
      <v-card-title>Profil użytkownika </v-card-title>
      <v-card-text>
        <h3>E-mail: {{ displayData.mail }}</h3>
        <h3 v-if="displayData.company != ''">
          Jednostka: {{ displayData.company }}
        </h3>
        <h3 v-if="displayData.regon != ''">Regon: {{ displayData.regon }}</h3>
        <div class="mt-5" v-if="!currentUser.chenge_pass">
          <v-icon>mdi-alert-box</v-icon>
          Aby korzystać z aplikacji musisz zresetować domyślne hasło.
          <v-icon>mdi-alert-box</v-icon>
        </div>

        <div class="d-flex flex-row mt-8">
          <v-tabs v-model="tab" direction="vertical" color="primary">
            <v-tab value="change-data">
              <v-icon start> mdi-lock-outline </v-icon>
              Zmiana hasła
            </v-tab>
            <!-- <v-tab value="manage-swd">
              <v-icon start> mdi-briefcase-edit-outline </v-icon>
              Moje kody SWD
            </v-tab> -->
          </v-tabs>
          <v-window v-model="tab" style="width: 100%">
            <v-window-item value="change-data">
              <change-password
                @loading="setLoading"
                @modal="showMsg"
                @success="showSuccessMsg"
              />
            </v-window-item>
            <v-window-item value="manage-swd">
              <manage-swd @loading="setLoading" />
            </v-window-item>
          </v-window>
        </div>
        <div class="d-flex flex-row mt-8" v-if="hasCompanyId">
          <v-tabs v-model="unitTab" direction="vertical" color="primary">
            <v-tab value="unit-data">Dane jednostki</v-tab>
          </v-tabs>
          <v-window v-model="unitTab" style="width: 100%">
            <v-window-item value="unit-data">
              <div class="mt-8">
                <v-card-title
                  >Podmiot zobowiązany ustawowo do stosowania standardu rachunku
                  kosztów zgodnie z Rozporządzeniem Ministra Zdrowia<br />
                  z dnia 26 października 2020 r. w sprawie zaleceń dotyczących
                  standardu rachunku kosztów u świadczeniodawców:</v-card-title
                >
                <v-radio-group v-model="isSrkSelection" row>
                  <v-radio label="Tak" :value="true"></v-radio>
                  <v-radio label="Nie" :value="false"></v-radio>
                </v-radio-group>
                <v-card-actions class="justify-center">
                  <v-btn
                    rounded="pill"
                    color="white"
                    style="background: #17728d"
                    @click="saveChanges()"
                  >
                    Zapisz zmiany
                  </v-btn>
                </v-card-actions>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-card-text>
    </v-card>
    <custom-modal
      :flag="flag"
      @close-modal="
        () => {
          flag = false;
        }
      "
    >
      <template v-slot:message>
        <div class="text-justify">{{ message }}</div>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomModal from "../components/layoutComponents/CustomModal.vue";
import ChangePassword from "../components/profileModule/ChangePassword.vue";
import ManageSwd from "../components/profileModule/ManageSwd.vue";
import api from "../services/api";

export default {
  name: "ProfileView",
  data() {
    return {
      flag: false,
      message: "",
      loading: false,
      tab: "change-data",
      unitTab: "unit-data",
      isSrkSelection: null,
    };
  },
  methods: {
    setLoading(flag) {
      this.loading = flag;
    },
    showMsg(flag, msg) {
      this.flag = flag;
      this.message = msg;
    },
    getSrkInfo(id) {
      api
        .get(`company/${id}/`)
        .then((response) => {
          this.isSrkSelection = response.data.is_srk;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    showSuccessMsg(flag, msg) {
      this.flag = flag;
      this.message = msg;

      // Close the message after 2 seconds (2000 milliseconds)
      setTimeout(() => {
        this.flag = false;
        this.message = "";
      }, 1000);
    },

    saveChanges() {
      this.loading = true;
      const payload = {
        is_srk: this.isSrkSelection,
      };
      api
        .patch(`company/${this.currentUser.company_users?.id}/`, payload)
        .then(() => {
          // On success, show a success message to the user
          this.showSuccessMsg(true, "Zmiany zostały zapisane pomyślnie.");
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser.currentUser,
      confirm: (state) => state.currentUser.isConfirm,
    }),
    displayData() {
      return {
        mail: this.currentUser.email || "",
        company: this.currentUser.company_users?.name || "",
        regon: this.currentUser.company_users?.regon || "",
      };
    },
    hasCompanyId() {
      return !!this.currentUser.company_users?.id;
    },
  },
  mounted() {
    api
      .get(`/users/current_user/`)
      .then((response) => {
        const companyId = response?.data?.company_users?.id;
        if (companyId) {
          this.getSrkInfo(companyId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  components: {
    CustomModal,
    ChangePassword,
    ManageSwd,
  },
};
</script>

<style></style>
