<template>
  <div class="my-8">
    <div class="text-right mr-16">
      <v-btn color="primary" variant="outlined" @click="$router.push('/')"
        >Powrót do strony głównej</v-btn
      >
    </div>
    <div class="mx-md-16 px-md-12 mx-5">
      <v-card-title class="justify-center mt-5 text-primary">
        Najczęściej zadawane pytania
      </v-card-title>
      <v-card-text class="mx-8 mb-8 mt-1">
        <strong class="text-primary"
          ><div class="text-center mb-2">Przejdź do sekcji</div></strong
        >
        <div className="text-center">
          <ul class="regulaminList scrollList">
            <li @click="scroll('tech')">TECHNICZNE</li>
            <li @click="scroll('main')">OGÓLNE</li>
            <li @click="scroll('opkList')">ARKUSZ: Lista OPK 3</li>
            <li @click="scroll('tableUop')">
              ARKUSZ: Tabela-UoP, dzieło, zlecenie 3
            </li>
            <li @click="scroll('tablePod')">
              ARKUSZ: Tabela-podwykonawstwo medyczne 4
            </li>
            <li @click="scroll('OpkWork')">ARKUSZ: Miejsca pracy - OPK 6</li>
            <li @click="scroll('RachWyn')">ARKUSZ: Rachunek wyników</li>
          </ul>
        </div>
        <div class="text-center text-primary mt-10 font-weight-bold" id="tech">
          TECHNICZNE
        </div>
        <div className="text-justify my-5">
          <ol class="regulaminList answerList">
            <question-element
              :type="'tech'"
              :QAList="QAList"
            ></question-element>
            <div
              class="text-center text-primary my-5 font-weight-bold"
              id="main"
            >
              OGÓLNE
            </div>
            <question-element
              :type="'ogol'"
              :QAList="QAList"
            ></question-element>
            <!-- <li></li>
            <div class="my-2"></div> -->

            <div
              class="text-center text-primary my-5 font-weight-bold"
              id="opkList"
            >
              ARKUSZ: Lista OPK
            </div>
            <question-element
              :type="'list'"
              :QAList="QAList"
            ></question-element>
            <div
              class="text-center text-primary my-5 font-weight-bold"
              id="tableUop"
            >
              ARKUSZ: Tabela-UoP, dzieło, zlecenie
            </div>
            <question-element :type="'uop'" :QAList="QAList"></question-element>
            <div
              class="text-center text-primary my-5 font-weight-bold"
              id="tablePod"
            >
              ARKUSZ: Tabela-podwykonawstwo medyczne
            </div>
            <question-element :type="'pod'" :QAList="QAList"></question-element>
            <div
              class="text-center text-primary my-5 font-weight-bold"
              id="OpkWork"
            >
              ARKUSZ: Miejsca pracy - OPK
            </div>
            <question-element
              :type="'miej'"
              :QAList="QAList"
            ></question-element>

            <div
              class="text-center text-primary my-5 font-weight-bold"
              id="RachWyn"
            >
              ARKUSZ: Rachunek wyników
            </div>
            <question-element
              :type="'miej'"
              :QAList="QAList"
            ></question-element>
          </ol>
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import QuestionElement from "../components/qandacomponents/QuestionElement.vue";
export default {
  name: "QAList",

  methods: {
    ...mapActions("questions", ["getQuestions"]),
    scroll(id) {
      document
        .getElementById(id)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
  },
  computed: {
    ...mapState({
      QAList: (state) => state.questions.questions,
    }),
  },
  created() {
    this.getQuestions();
  },
  components: {
    QuestionElement,
  },
};
</script>

<style lang="scss">
.answerList {
  li {
    font-weight: bold;
  }
}
.scrollList {
  li {
    cursor: pointer;
  }
  li:hover {
    font-weight: bold;
  }
  list-style-type: none;
}
</style>
