<template>
  <div>
    <div class="mx-md-16 px-md-12 mx-5">
      <v-card-title class="justify-center mt-5 text-primary"
        >Regulamin serwisu</v-card-title
      >
      <v-card-text>
        <h5 class="text-center mt-5 text-uppercase">
          <strong class="text-primary"
            >§ 1<br /><span> Informacje ogólne</span></strong
          >
        </h5>
        <div className="text-justify">
          <ol class="regulaminList">
            <li>
              Niniejszy regulamin Aplikacji appRKP, zwaną dalej „Aplikacją”
              udostępnionej pod adresem https://apprkp.aotm.gov.pl/, zwany dalej
              „Regulaminem”, określa zasady korzystania z&nbsp;serwisu
              udostępnionego przez Agencję Oceny Technologii Medycznych
              i&nbsp;Taryfikacji z&nbsp;siedzibą w&nbsp;Warszawie,
              ul.&nbsp;Przeskok 2, 00-032&nbsp;Warszawa.
            </li>
            <li>
              Właścicielem Aplikacji jest Agencja Oceny Technologii Medycznych
              i&nbsp;Taryfikacji, zwana dalej „Administratorem” lub
              „Usługodawcą”.
            </li>
          </ol>
        </div>
        <h5 class="text-center mt-5 text-uppercase">
          <strong class="text-primary"
            >§ 2 <br /><span
              >Przeznaczenie i&nbsp;funkcjonalność Aplikacji</span
            ></strong
          >
        </h5>
        <div className="text-justify">
          <ol class="regulaminList">
            <li>
              Usługa Aplikacja appRKP jest bezpłatna i&nbsp;polega
              na&nbsp;udostępnieniu świadczeniodawcom Aplikacji umożliwiającej
              cykliczne przygotowywanie i&nbsp;przekazywanie do&nbsp;Agencji
              informacji o&nbsp;pracownikach zatrudnionych w&nbsp;podmiocie oraz
              ich&nbsp;wynagrodzeniach, na&nbsp;podstawie art.&nbsp;31lc ust.2
              i&nbsp;4 ustawy z&nbsp;dnia 27&nbsp;sierpnia 2004&nbsp;roku
              o&nbsp;świadczeniach opieki zdrowotnej finansowanych
              ze&nbsp;środków publicznych (Dz.&nbsp;U. z&nbsp;2021&nbsp;r.,
              poz.&nbsp;1285&nbsp;tj.).
            </li>
            <li>
              Aplikacja umożliwia świadczeniodawcom:<br />
              <div class="ml-5">
                a) pobranie do&nbsp;wypełnienia formularza w&nbsp;postaci pliku
                MS&nbsp;Excel, dotyczącego informacji o&nbsp;zatrudnionych
                pracownikach oraz ich&nbsp;wynagrodzeniach,
                <br />
                b) przekazanie wypełnionego szablonu pliku poprzez jego import
                do&nbsp;aplikacji,
                <br />
                c) edytowanie poszczególnych danych wgranych do&nbsp;aplikacji
                w&nbsp;formularzu.
              </div>
            </li>
            <li>
              Przekazanie danych za&nbsp;pomocą Aplikacji jest równoznaczne
              z&nbsp;wyrażeniem zgody na&nbsp;ich wykorzystanie przez Agencję
              w&nbsp;celu realizacji zadań Agencji określonych
              w&nbsp;art.&nbsp;31n ustawy z&nbsp;dnia 27&nbsp;sierpnia
              2004&nbsp;roku o&nbsp;świadczeniach opieki zdrowotnej
              finansowanych ze&nbsp;środków publicznych (Dz.&nbsp;U.
              z&nbsp;2021&nbsp;r., poz.&nbsp;1285&nbsp;t.j.).
            </li>
          </ol>
        </div>
        <h5 class="text-center mt-5 text-uppercase">
          <strong class="text-primary"
            >§ 3 <br /><span>Rejestracja Użytkownika</span></strong
          >
        </h5>
        <div className="text-justify">
          <ol class="regulaminList">
            <li>
              Posiadanie konta jest bezpłatne, ale niezbędne do&nbsp;korzystania
              z&nbsp;funkcjonalności Aplikacji.
            </li>
            <!-- <li>
              Do założenia konta w&nbsp;Aplikacji przez Użytkownika niezbędny
              jest dostęp do&nbsp;sieci Internet oraz wypełnienie formularza
              rejestracji poprzez podanie następujących danych:<br />
              <div class="ml-5">
                a) imię i&nbsp;nazwisko,<br />
                b) adres mailowy,<br />
                c) numer telefonu.<br />
              </div>
            </li> -->
            <li>
              Konta są zakładane automatycznie przez system z&nbsp;wygenerowanym
              hasłem. Poświadczenia do&nbsp;logowania dostarczane są&nbsp;drogą
              mailową.
            </li>
            <li>
              W razie nieotrzymania przez Użytkownika wiadomości, konieczne jest
              wysłanie maila z&nbsp;prośbą o&nbsp;założenie konta na&nbsp;adres
              apprkp@aotm.gov.pl.
            </li>
            <li>
              Po&nbsp;zalogowaniu Użytkownik ma obowiązek zmiany hasła
              i&nbsp;uzupełnienia danych w&nbsp;celu korzystania
              z&nbsp;pozostałych funkcjonalności aplikacji.
            </li>
            <li>
              Hasło pozwalające Użytkownikowi logować się do Aplikacji jest
              prywatne i&nbsp;poufne.
            </li>
          </ol>
        </div>
        <h5 class="text-center mt-5 text-uppercase">
          <strong class="text-primary"
            >§ 4 <br /><span>Zasady korzystania z Aplikacji</span></strong
          >
        </h5>
        <div className="text-justify">
          <ol class="regulaminList">
            <li>
              Użytkownik poprzez zalogowanie w&nbsp;Aplikacji akceptuje warunki
              korzystania z&nbsp;Aplikacji określone w&nbsp;Regulaminie.
            </li>
            <li>
              Użytkownik zobowiązany jest do&nbsp;korzystania z&nbsp;Aplikacji
              w&nbsp;sposób zgodny z&nbsp;niniejszym Regulaminem, obowiązującym
              prawem, zasadami współżycia społecznego oraz dobrymi obyczajami.
            </li>
            <li>
              Każde działanie Użytkownika powinno odbywać&nbsp;się równocześnie
              z&nbsp;poszanowaniem dla&nbsp;dóbr osób trzecich - zwłaszcza
              innych Użytkowników korzystających z&nbsp;Aplikacji.
            </li>
            <li>
              Podczas korzystania z&nbsp;Aplikacji obowiązkiem Użytkownika jest
              podanie prawdziwych, zgodnych ze stanem faktycznym danych oraz
              ich&nbsp;ochrona przed dostępem osób nieuprawnionych. Zabronione
              jest również udostępnianie swojego konta innym Użytkownikom
              i&nbsp;osobom trzecim, jak również korzystanie z&nbsp;kont
              należących do&nbsp;innych Użytkowników.
            </li>
            <li>
              Każdy Użytkownik mający dostęp do&nbsp;Aplikacji zobowiązany jest
              do&nbsp;powstrzymywania się od&nbsp;kopiowania, modyfikowania,
              rozpowszechniania, transmitowania lub wykorzystywania w&nbsp;inny
              sposób jakichkolwiek treści udostępnionych na&nbsp;stronie
              Aplikacji, z&nbsp;wyjątkiem korzystania z&nbsp;nich w&nbsp;ramach
              dozwolonego użytku.
            </li>
            <li>
              Niedozwolone są&nbsp;jakiekolwiek próby wprowadzania
              do&nbsp;Aplikacji szkodliwych danych (oprogramowanie złośliwe
              w&nbsp;tym boty, wirusy, pliki szpiegujące, "robaki"&nbsp;etc.).
            </li>
            <li>
              Użytkownik jest zobowiązany niezwłocznie powiadomić Administratora
              Aplikacji o&nbsp;każdym przypadku naruszenia praw w&nbsp;związku
              z&nbsp;korzystaniem z&nbsp;Aplikacji.
            </li>
            <li>
              Administrator informuje, że&nbsp;Użytkownik ponosi pełną
              odpowiedzialność za&nbsp;naruszenie prawa bądź szkodę wyrządzoną
              działaniem Użytkownika związanym z&nbsp;korzystaniem przez niego
              z&nbsp;Aplikacji, w&nbsp;szczególności posłużeniem się lub
              podaniem do wiadomości publicznej przetwarzanych danych osobowych
              uzyskanych za&nbsp;pomocą funkcji oferowanych przez Aplikację,
              w&nbsp;tym naruszenie ich&nbsp;dóbr osobistych, prywatności lub
              zasad przetwarzania danych osobowych.
            </li>
          </ol>
        </div>
        <h5 class="text-center mt-5 text-uppercase">
          <strong class="text-primary"
            >§ 5 <br /><span>Prawa i obowiązki Administratora</span></strong
          >
        </h5>
        <div className="text-justify">
          <ol class="regulaminList">
            <li>
              Administrator zobowiązuje się sprawować nadzór
              nad&nbsp;technicznym funkcjonowaniem Aplikacji.
            </li>
            <li>
              Administrator nie gwarantuje niezakłóconego dostępu
              do&nbsp;Aplikacji oraz nie&nbsp;zapewnia stałej dostępności
              wszystkich funkcji Aplikacji i&nbsp;ich&nbsp;bezbłędnego
              działania.
            </li>
            <li>
              Administrator nie ponosi odpowiedzialności za&nbsp;jakiekolwiek
              szkody i&nbsp;utracone korzyści poniesione przez Użytkownika
              w&nbsp;związku: - funkcjonowaniem Aplikacji, w&nbsp;szczególności
              zakłóceniem dostępności wszystkich funkcji Aplikacji bądź ich
              błędnym działaniem, - naruszeniem przez Użytkownika praw osób
              trzecich, - funkcjonowaniem interfejsów oraz łączy
              telekomunikacyjnych, które nie&nbsp;są&nbsp;własnością
              Administratora lub nie&nbsp;są&nbsp;przez niego obsługiwane, -
              usługami, aplikacjami i&nbsp;serwisami internetowymi, które nie
              są&nbsp;własnością Administratora lub nie&nbsp;są&nbsp;przez niego
              obsługiwane.
            </li>
            <li>
              Administrator nie&nbsp;ponosi odpowiedzialności
              za&nbsp;niezamierzone użycie Aplikacji oraz informacje
              i&nbsp;treści dostępne w&nbsp;Aplikacji.
            </li>
            <li>
              Administrator ma prawo zablokować konto Użytkownika, jeżeli
              poweźmie uzasadnione podejrzenie, że&nbsp;Użytkownik korzysta
              z&nbsp;Aplikacji sprzecznie z&nbsp;zasadami określonymi
              w&nbsp;Regulaminie.
            </li>
            <li>
              Administrator ma prawo czasowo zawiesić działanie Aplikacji, celem
              przeprowadzenia działań technicznych Aplikacji, dokonania zmian
              w&nbsp;działaniu Aplikacji bądź zapobieżeniu ewentualnym szkodom.
            </li>
            <li>
              Administrator zastrzega sobie prawo zmiany Regulaminu, Polityki
              Prywatności oraz modyfikacji Aplikacji, o&nbsp;czym powiadomi
              Użytkownika z&nbsp;wyprzedzeniem 14&nbsp;dniowym na&nbsp;adres
              e-mail.
            </li>
            <li>
              Reklamacje związane z&nbsp;funkcjonowaniem Aplikacji można
              zgłaszać za&nbsp;pośrednictwem poczty elektronicznej pod adresem:
              apprkp@aotm.gov.pl.
            </li>
          </ol>
        </div>
        <h5 class="text-center mt-5 text-uppercase">
          <strong class="text-primary"
            >§ 6 <br /><span>Prawa Autorskie</span></strong
          >
        </h5>
        <div className="text-justify">
          <ol class="regulaminList">
            <li>
              Majątkowe prawa autorskie do&nbsp;treści publikowanych
              w&nbsp;Aplikacji należą do Właściciela aplikacji. Prawa autorskie
              podlegają ochronie przewidzianej w&nbsp;ustawie z&nbsp;dnia
              4&nbsp;lutego 1994&nbsp;roku o&nbsp;prawie autorskim
              i&nbsp;prawach pokrewnych (Dz.&nbsp;U. z&nbsp;2021&nbsp;r.
              poz.&nbsp;1062 z&nbsp;poź.&nbsp;zm.).
            </li>
            <li>
              Z chwilą pierwszego zalogowania w&nbsp;Aplikacji Administrator
              udziela Użytkownikowi niewyłącznej licencji na&nbsp;korzystanie
              z&nbsp;Aplikacji na&nbsp;warunkach określonych poniżej.
            </li>
            <li>
              Udzielona licencja jest niewyłączna, nieprzenoszalna, nie uprawnia
              do&nbsp;udzielania dalszych licencji (sublicencji).
            </li>
            <li>
              Licencja jest udzielana na&nbsp;czas nieoznaczony i&nbsp;bez
              ograniczeń terytorialnych.
            </li>
            <li>
              Na podstawie udzielonej licencji Użytkownik jest uprawniony
              do&nbsp;zwielokrotniania Aplikacji w&nbsp;zakresie niezbędnym
              do&nbsp;jej używania na&nbsp;posiadanych przez Użytkownika
              urządzeniach mobilnych, jeżeli czynności
              te&nbsp;są&nbsp;podejmowane dla celów korzystania z&nbsp;Aplikacji
              zgodnie z&nbsp;Regulaminem.
            </li>
            <li>
              Korzystanie z&nbsp;treści publikowanych w&nbsp;Aplikacji
              nie&nbsp;oznacza nabycia przez Użytkownika jakichkolwiek praw
              autorskich. Użytkownik może korzystać z&nbsp;tych treści jedynie
              w&nbsp;zakresie dozwolonym w&nbsp;Ustawie o&nbsp;prawie autorskim
              i&nbsp;prawach pokrewnych.
            </li>
            <li>
              Żadna część, jak i&nbsp;całość treści, z&nbsp;których Użytkownik
              korzystał, z&nbsp;uwzględnieniem ust.&nbsp;5, nie&nbsp;może być
              powielana i&nbsp;rozpowszechniana, w&nbsp;jakiejkolwiek formie
              i&nbsp;w&nbsp;jakikolwiek inny sposób (elektroniczny lub
              mechaniczny), włącznie z&nbsp;kopiowaniem i&nbsp;zamieszczaniem
              w&nbsp;Internecie. Użycie lub wykorzystanie treści w&nbsp;całości
              lub w&nbsp;części, bez zgody Usługodawcy jest zabronione.
            </li>
          </ol>
        </div>
        <h5 class="text-center mt-5 text-uppercase">
          <strong class="text-primary"
            >§ 7 <br /><span>Data obowiązywania Regulaminu</span></strong
          >
        </h5>
        <ul style="list-style-type: none">
          <li>
            <div className="text-justify">
              Niniejszy Regulamin obowiązuje od
              <strong>08.04.2022&nbsp;roku.</strong>
            </div>
          </li>
        </ul>
        <br />
        <div className="text-right">
          <v-btn color="primary" variant="outlined" @click="$router.back()"
            >Powrót</v-btn
          >
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsServices",
};
</script>

<style></style>
