<template>
  <v-container class="grey lighten-5">
    <v-row align="start" justify="center">
      <v-col cols="12" sm="6" md="2" xl="2">
        <date-picker
          v-model="date"
          label="Okres"
          monthPicker
          class="mt-3 datePickerPrimary"
          locale="pl"
          selectText="Wybierz"
          cancelText=""
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="2" xl="2">
        <v-select
          label="Kod SWD/oddział"
          variant="underlined"
          class="mt-1"
          v-model="nfzNumber"
          :items="kodsw"
          :item-title="(kodsw) => kodsw.kod_sw + '/' + kodsw.oddzial_ow"
          return-object
        >
          <template v-slot:no-data>
            <div class="px-4">-</div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="12" md="5" xl="6">
        <v-file-input label="Wybierz plik" v-model="file" accept=".xls,.xlsx" />
      </v-col>
      <v-col cols="12" sm="12" md="3" xl="2" class="text-center">
        <v-btn
          v-if="is_staff"
          color="primary"
          variant="outlined"
          @click="uploadFile()"
          class="mt-md-3"
        >
          Wyślij do walidacji
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="errorReportDialog" scrollable width="80vw">
      <v-card>
        <v-card-title>Raport błędów</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 80vh">
          <div
            v-for="(item, i) in formattedErrorReport"
            :key="'error_' + i"
            v-html="item"
            class="mb-4"
          />
          <div v-if="formattedErrorReport == 0">Nie wykryto błędów</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="exportErrors()"
            v-if="formattedErrorReport != 0"
          >
            Pobierz raport błędów
          </v-btn>
          <v-btn color="primary" text @click="importData()" v-else>
            Importuj dane
          </v-btn>
          <v-btn color="primary" text @click="closeDialog()"> Zamknij </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" hide-overlay persistent>
      <v-card color="primary">
        <v-card-text style="color: white">
          Proszę czekać...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="validationLoading" hide-overlay persistent>
      <v-card color="primary">
        <v-card-text style="color: white">
          Trwa weryfikowanie pliku. Może to zająć kilka minut. Proszę pozostać
          na stronie...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="importLoading" hide-overlay persistent>
      <v-card color="primary">
        <v-card-text style="color: white">
          Trwa importowanie pliku. Proszę czekać...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <custom-modal
      :flag="flag"
      :color="color"
      @close-modal="
        () => {
          flag = false;
          message = '';
          color = '';
        }
      "
      ><template v-slot:message>
        <div class="text-justify" v-html="message"></div>
        <v-divider />
      </template>
    </custom-modal>
    <custom-snackbar :snackbar="snackbar" @clear="updateSnackbar" />
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import api from "../../services/api";
import { saveAs } from "file-saver";
import CustomModal from "../layoutComponents/CustomModal.vue";
import CustomSnackbar from "../layoutComponents/CustomSnackbar.vue";

export default {
  name: "ImportForm",
  components: { CustomModal, CustomSnackbar },
  props: ["company", "userId", "errorReport"],
  data() {
    return {
      items: [],
      snackbar: {
        open: false,
        text: "",
        color: "",
      },
      date: {
        month: 1, //default month in current edition
        year: new Date().getFullYear(),
      },
      nfzNumber: { kod_sw: "", oddzial_ow: "" },
      file: [],

      errorReportDialog: false,
      loading: false,
      validationLoading: false,
      importLoading: false,
      uploadedId: null,
      message: "",
      flag: false,
      color: "",
      is_staff: false,
      is_newest: false,
    };
  },
  methods: {
    ...mapActions("kodsw", ["getMyKodsw"], ""),
    updateSnackbar(
      msg = {
        open: false,
        text: "",
        color: "",
      }
    ) {
      this.snackbar = msg;
    },
    uploadFile() {
      this.validationLoading = true;
      if (this.company === null) {
        this.validationLoading = false;
        this.updateSnackbar({
          open: true,
          text: "Brak przypisanej firmy.",
          color: "error",
        });
      } else if (this.nfzNumber.kod_sw === "") {
        this.validationLoading = false;
        this.updateSnackbar({
          open: true,
          text: "Nie wybrano kodu świadczeniodawcy",
          color: "error",
        });
      } else {
        //check if file already exist
        const params = {
          company: this.company.id,
          nfz_number: this.nfzNumber.kod_sw.trim(),
          year: this.date.year,
          month: this.date.month + 1,
        };
        api
          .get(`fileupload/`, { params })
          .then((response) => {
            const dataDict = {
              file: this.file[0],
              year: this.date.year,
              month: this.date.month + 1,
              nfz_number: this.nfzNumber.kod_sw.trim(),
              oddzial_ow: this.nfzNumber.oddzial_ow,
              user_id: this.userId,
              company: this.company.id,
            };
            let dataToSend = new FormData();
            Object.entries(dataDict).forEach(([key, value]) => {
              dataToSend.append(key, value);
            });

            if (response.data.length) {
              api
                .put(`fileupload/${response.data[0].id}/`, dataToSend)
                .then((res) => {
                  this.uploadedId = res.data.id;
                  this.checkFile();
                })
                // .then(() => {
                //   window.location.reload();
                // })
                .catch((err) => {
                  this.uploadError(err);
                });
            } else {
              api
                .post(`fileupload/`, dataToSend)
                .then((res) => {
                  this.uploadedId = res.data.id;
                  this.checkFile();
                  this.updateSnackbar({
                    open: true,
                    text: "Udało się. Pliki zostały wysłane do walidacji.",
                    color: "success",
                  });
                })
                // .then(() => {
                //   window.location.reload();
                // })
                .catch((err) => {
                  this.uploadError(err);
                });
            }
          })
          .catch(() => {
            this.validationLoading = false;
            this.updateSnackbar({
              open: true,
              text: "Błąd serwera.",
              color: "error",
            });
          });
      }
    },
    checkFile() {
      this.$emit("updateList");
      this.$emit("updateFileIsValidated", "updated");
      this.$emit("updateStatusID", this.uploadedId);

      api
        .get(`fileupload/${this.uploadedId}/check_data_from_file/`)
        .then((res) => {
          this.validationLoading = false;
          const newState = res.data;
          this.$emit("updateErrorReport", newState);
          // this.errorReportDialog = true;
          this.nfzNumber = { kod_sw: "", oddzial_ow: "" };
          if (res) {
            const intervalId = setInterval(() => {
              api
                .get(`task-status/${res.data.task_id}/`)
                .then((response) => {
                  this.$emit(
                    "updateFileIsValidatedStatus",
                    response.data.status
                  );

                  // Sprawdź, czy status jest "completed"
                  if (response.data.status === "completed") {
                    clearInterval(intervalId); // Zakończ interwał
                    this.$emit("updateFileIsValidated", null);
                    this.$emit("updateFileIsImported", null);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  clearInterval(intervalId); // Zakończ interwał w przypadku błędu
                });
            }, 5000);
          }
        })
        .catch(() => {
          this.validationLoading = false;
          this.updateSnackbar({
            open: true,
            text: "Wystąpił błąd przy walidacji pliku.",
            color: "error",
          });
        });
    },
    uploadError(error) {
      let errorMsg = "Wystąpił błąd przy imporcie.";
      const invalidFields = Object.keys(error.data);
      const additionalErrors = {
        file: " " + (error.data.file ? error.data.file.join(" ") : ""),
        year: " Niepoprawny rok.",
        month: " Niepoprawny miesiąc.",
        nfz_number: " Niepoprawny kod świadczeniodawcy.",
        user_id: " Niepoprawnie przypisany użytkownik.",
        company: " Niepoprawnie przypisana firma.",
      };
      invalidFields.forEach((item) => {
        if (additionalErrors[item]) errorMsg += additionalErrors[item];
      });
      this.updateSnackbar({
        open: true,
        text: errorMsg,
        color: "error",
      });
      this.validationLoading = false;
    },
    exportErrors() {
      var XLSX = require("xlsx");
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(
        [
          {
            desc: "OPIS BŁĘDU",
            sheet_name: "NAZWA ARKUSZA",
            column_name: "NAZWA KOLUMNY",
            row_number: "NAZWA WIERSZA",
            false_val: "NIEPRAWIDŁOWA WARTOŚĆ",
            true_val: "OCZEKIWANA WARTOŚĆ",
          },
        ].concat(this.errorReport),
        {
          header: [
            "desc",
            "sheet_name",
            "column_name",
            "row_number",
            "false_val",
            "true_val",
          ],
          skipHeader: true,
        }
      );
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, "Raport błędów" + fileExtension);
    },
    importData() {
      this.importLoading = true;
      this.errorReportDialog = false;
      api
        .get(`fileupload/${this.uploadedId}/data_import/`)
        .then(() => {
          this.importLoading = false;
          this.uploadedId = null;
          this.message =
            `Dziękujemy za przesłane dane. Dane firmy <strong>${this.company.name}</strong>` +
            ` o regonie <strong>${this.company.regon}</strong> zostały poprawnie zaimportowane.` +
            `<p>Niniejszy dokument nie stanowi podstawy do potwierdzenia współpracy z AOTMiT w toku postępowania w sprawie zawarcia umowy o udzielanie świadczeń opieki zdrowotnej z NFZ.</p>`;
          `<p class='text-right my-2'>Z poważaniem AOTMiT.</p>`;
          this.color = "success";
          this.flag = true;
          this.$emit("updateList");
        })
        .catch(() => {
          this.importLoading = false;
          this.updateSnackbar({
            open: true,
            text: "Wystąpił błąd przy imporcie danych.",
            color: "error",
          });
        });
    },
    closeDialog() {
      this.errorReportDialog = false;
      this.uploadedId = null;
    },
  },
  computed: {
    formattedErrorReport() {
      return this.errorReport.map((item) => {
        let msg = "";
        if (item.sheet_name) {
          msg = `W arkuszu o nazwie <strong>${item.sheet_name}</strong> wystąpił błąd `;
        } else msg = "Wystąpił błąd ";
        msg += `<strong>${item.desc}</strong>. Nieprawidłowa wartość`;
        if (item.false_val) msg += ` <strong>${item.false_val}</strong>`;
        if (item.column_name)
          msg += ` w kolumnie <strong>${item.column_name}</strong>`;
        if (item.row_number)
          msg += ` w wierszu <strong>${item.row_number}</strong>`;
        if (item.true_val)
          msg += ` powinna być zastąpiona <strong>${item.true_val}</strong>`;
        return msg + ".";
      });
    },
    isDisabled() {
      return !this.nfzNumber;
    },
    ...mapState({
      kodsw: (state) => state.kodsw.kodsw,
    }),
  },
  watch: {
    userId() {
      if (this.userId) this.loading = false;
    },
  },

  created() {
    if (!this.userId) this.loading = true;
    this.getMyKodsw();
  },
  mounted() {
    api
      .get(`/users/current_user/`)
      .then((response) => {
        this.is_staff = response.data.is_staff;
        this.is_newest = response.data.is_newest;
        console.log(response.data.is_staff);
      })
      .then(() => {})
      .catch(() => {});
  },
};
</script>

<style></style>
