<template>
  <div>
    <v-card>
      <v-dialog v-model="loading" hide-overlay persistent>
        <v-card color="primary">
          <v-card-text style="color: white">
            Ładowanie...
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card-text>
        <v-btn variant="text" color="primary" @click="isArch = !isArch">
          <span v-if="!isArch">Zobacz zarchiwizowane</span>
          <span v-else>Zobacz aktualne</span>
        </v-btn>
        <v-table density="compact">
          <thead>
            <tr>
              <th class="text-left">Rodzaj problemu</th>
              <th class="text-left">
                Nazwa firmy<br />
                Regon
              </th>
              <th class="text-left">Email<br />Telefon</th>
              <th class="text-left">Opis</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody class="pb-6">
            <template v-for="bug in bugs.results" :key="bug.id">
              <tr>
                <td>{{ bug.bug_list_bug.issue }}</td>
                <td>{{ bug.name }}<br />{{ bug.regon }}</td>
                <td>{{ bug.email }}<br />{{ bug.phone }}</td>
                <td>{{ bug.description }}</td>
                <td>
                  <v-btn variant="text" color="primary" v-if="!bug.is_bug_fix">
                    <span @click="achiveBug(bug.id, bug, true)"
                      >Archiwizuj</span
                    >
                  </v-btn>
                  <v-btn variant="text" color="primary" v-else>
                    <span @click="achiveBug(bug.id, bug, false)"
                      >Zarchiwizowano - cofnij</span
                    >
                  </v-btn>
                </td>
              </tr></template
            >
          </tbody></v-table
        >
        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col :cols="calculateCols(bugs.count)">
                <v-container class="max-width">
                  <v-pagination
                    v-model="page"
                    :length="pageNum(bugs.count)"
                    class="my-4"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div> </v-card-text
    ></v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "BugList",
  data() {
    return {
      page: 1,
      size: 20,
      isArch: false,
    };
  },
  computed: {
    ...mapState({
      bugs: (state) => state.bug.bug,
      loading: (state) => state.bug.loading,
    }),
  },
  watch: {
    page(newVal) {
      this.filter(newVal);
    },
    isArch() {
      this.changeStatus();
    },
  },
  methods: {
    ...mapActions("bug", ["getBugs", "putBug"]),
    pageNum(numOfPages) {
      if (numOfPages) return Math.ceil(numOfPages / this.size);
    },
    calculateCols(numOfPages) {
      return Math.ceil(numOfPages / this.size) < 4
        ? Math.ceil(numOfPages / this.size)
        : 4;
    },
    filter(page = null) {
      let params = { size: this.size };
      page ? (params["page"] = this.page) : (this.page = 1);
      this.getBugs({ params }).catch((error) => {
        console.log(error);
      });
    },
    changeStatus() {
      let params = { size: this.size, is_bug_fix: this.isArch };
      this.getBugs({ params }).catch((error) => {
        console.log(error);
      });
    },
    achiveBug(id, payload, flag) {
      payload.is_bug_fix = flag;
      this.putBug([id, payload])
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    let params = { size: this.size, is_bug_fix: false };
    this.getBugs({ params }).catch((error) => {
      console.log(error);
    });
  },
  components: {},
};
</script>

<style></style>
