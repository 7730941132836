<template>
  <div>
    <v-btn color="primary" variant="outlined" @click="getRaport()">
      Pobierz raport z walidacji plików
    </v-btn>

    <v-dialog v-model="loading" hide-overlay persistent>
      <v-card color="primary">
        <v-card-text style="color: white">
          Proszę czekać, trwa generowanie raportu...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ReportButton",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getRaport() {
      this.loading = true;
      axios({
        method: "get",
        url: process.env.VUE_APP_API_ENDPOINT2 + `fileupload/get_raport/`,
        responseType: "blob",
      })
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Raport walidacji plików.xlsx");
          document.body.appendChild(fileLink);
          this.loading = false;
          fileLink.click();
        })
        .catch(() => {
          this.$emit("showMessage", {
            open: true,
            text: "Wystąpił błąd przy pobieraniu",
            color: "accent-4",
          });
          console.log("error occured");
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
