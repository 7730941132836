<template>
  <div>
    <v-card elevation="1" :class="$vuetify.display.mobile ? 'mt-16' : ''">
      <v-tabs v-model="tab" background-color="primary" centered stacked>
        <v-tab value="users">
          <v-icon>mdi-account-edit</v-icon>
          Użytkownicy
        </v-tab>
        <v-tab value="companies"> <v-icon>mdi-domain</v-icon>Firmy</v-tab>
        <v-tab value="import">
          <v-icon>mdi-application-import</v-icon>Import</v-tab
        >
        <v-tab value="bugs">
          <v-icon>mdi-bug-outline</v-icon>Raport błędów</v-tab
        >
        <v-tab value="qna" v-if="false">
          <v-icon>mdi-chat-question-outline</v-icon>Formularz Q&A</v-tab
        >
      </v-tabs>
      <v-window v-model="tab" v-if="!unauth">
        <v-window-item value="users">
          <manage-users />
        </v-window-item>
        <v-window-item value="companies">
          <companies />
        </v-window-item>
        <v-window-item value="import">
          <import-file />
        </v-window-item>
        <v-window-item value="bugs"> <bug-list /> </v-window-item>
        <v-window-item value="qna"> <qna-component /></v-window-item>
      </v-window>
    </v-card>

    <v-dialog v-model="unauth">
      <v-card>
        <v-card-text>
          Nie masz uprawnień do przeglądania do tej strony.
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            @click="
              unauth = false;
              $router.push('/import');
            "
            >Rozumiem</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ManageUsers from "../components/dashboardModule/ManageUsers.vue";
import Companies from "../components/dashboardModule/Companies.vue";
import BugList from "../components/dashboardModule/BugList.vue";
import { mapState } from "vuex";
import QnaComponent from "../components/dashboardModule/QnaComponent.vue";
import ImportFile from "../components/dashboardModule/ImportFile.vue";

export default {
  name: "DashboardView",
  components: { ManageUsers, Companies, BugList, QnaComponent, ImportFile },
  data() {
    return { tab: null, unauth: false };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser.currentUser,
    }),
    tabSize() {
      return this.tab.length;
    },
  },
  watch: {
    currentUser() {
      if (!this.currentUser.is_staff) this.unauth = true;
    },
  },
  created() {
    if (this.currentUser.is_staff != undefined) {
      this.unauth = !this.currentUser.is_staff;
    }
  },
};
</script>

<style></style>
