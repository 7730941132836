import api2 from "../../services/api2";

const state = {
  materialList: [],
  loading: false,
};

const getters = {
  maetrialList: (state) => {
    return state.materialList;
  },
};

const actions = {
  getMaterialList({ commit }) {
    return new Promise((resolve, reject) => {
      api2
        .get(`materials/`)
        .then((response) => {
          commit("setMaterialList", response);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

const mutations = {
  setMaterialList(state, response) {
    state.materialList = response.data;
  },

  setLoading(state, flag) {
    state.loading = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
