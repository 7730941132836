import api from "../../services/api";
import api2 from "../../services/api2";

const state = {
  bugList: [],
  bug: [],
  loading: false,
};

const getters = {
  bugList: (state) => {
    return state.bugList;
  },
  bug: (state) => {
    return state.bug;
  },
};

const actions = {
  getBugList({ commit }) {
    api2
      .get(`buglist/`)
      .then((response) => {
        commit("setBugList", response.data.results);
      })
      .catch((err) => {
        console.error("Error fetching bug list:", err);
      });
  },

  getBugs({ commit }, params) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      api
        .get(`bug/`, params)
        .then((response) => {
          commit("setBug", response);
          commit("setLoading", false);
          resolve(response);
        })
        .catch((err) => {
          commit("setLoading", false);
          console.log(err);
          reject(err);
        });
    });
  },
  postBug({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api2
        .post(`bug/`, payload)
        .then((response) => {
          commit("setBug", response);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  putBug({ commit }, [id, payload]) {
    return new Promise((resolve, reject) => {
      api
        .put(`bug/${id}/`, payload)
        .then((response) => {
          commit("updateBug", response);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

const mutations = {
  setBugList(state, bugList) {
    state.bugList = bugList;
  },
  setBug(state, response) {
    state.bug = response.data;
  },
  updateBug(state, response) {
    let index = state.bug.results.findIndex((el) => el.id === response.data.id);
    state.bug.results[index] = response.data;
  },
  setLoading(state, flag) {
    state.loading = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
