<template>
  <div v-if="checkBr()" class="ma-auto mt-16 text-center">
    Aplikacja desktopowa Internet Explorer zostanie wycofana 15 czerwca 2022
    r.<br />
    Zaleca się korzystanie z aplikacji RKP przy pomocy przeglądarek: Chrome,
    Firefox, Opera, Edge.
  </div>
  <div v-else>
    <div>
      <v-img
        src="./assets/front_logo.png"
        loading="lazy"
        min-height="380"
        cover
        @click="$router.push('/')"
        style="cursor: pointer"
      ></v-img>
    </div>
    <div class="mb-10 footerMargin"><slot /></div>
    <custom-footer />
  </div>
</template>

<script>
import CustomFooter from "./components/layoutComponents/CustomFooter.vue";
export default {
  name: "mainLayout",
  components: { CustomFooter },
  methods: {
    checkBr() {
      if (
        navigator.userAgent.indexOf("MSIE") != -1 ||
        !!document.documentMode == true
      ) {
        return true; //crap
      } else return false;
    },
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .footerMargin {
    margin-bottom: 110px !important;
  }
}
</style>
