import api from "../../services/api";

const state = {
  userList: [],
  userSelect: [],
  companyList: [], //for select in user management
  companies: [],
  loading: false, //for companies tab in dashboard
};

const getters = {
  isConfirm: (state) => {
    return state.isConfirm;
  },
};

const actions = {
  getAllUsers({ commit }, params) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      api
        .get(`users/`, params)
        .then((users) => {
          commit("setAllUsers", users.data);
          commit("setLoading", false);
          resolve(users.data);
        })
        .catch((err) => {
          commit("setLoading", false);
          console.log(err);
          reject(err);
        });
    });
  },
  createUser({ commit }, newUser) {
    return new Promise((resolve, reject) => {
      api
        .post(`users/`, newUser)
        .then((user) => {
          commit("addUser", user.data);
          resolve(user.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  editUser({ commit }, changedUser) {
    return new Promise((resolve, reject) => {
      api
        .put(`users/${changedUser.id}/`, changedUser.data)
        .then((user) => {
          commit("alterUser", user.data);
          resolve(user.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getUserSelect({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`users/`, params)
        .then((users) => {
          commit("setUserSelect", users.data);
          resolve(users.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  resetUserSelect({ commit }) {
    commit("setUserSelect", { results: [] });
  },
  getAllCompanies({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`companyselect/`, params)
        .then((companies) => {
          commit("setAllCompanies", companies.data);
          resolve(companies.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  resetAllCompanies({ commit }) {
    commit("setAllCompanies", []);
  },
  getCompanies({ commit }, params) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      api
        .get(`company/`, params)
        .then((companies) => {
          commit("setCompanies", companies.data);
          commit("setLoading", false);
          resolve(companies.data);
        })
        .catch((err) => {
          console.log(err);
          commit("setLoading", false);
          reject(err);
        });
    });
  },
  createCompany({ commit }, newCompany) {
    return new Promise((resolve, reject) => {
      api
        .post(`company/`, newCompany)
        .then((company) => {
          commit("addCompany", company.data);
          resolve(company.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  editCompany({ commit }, changedCompany) {
    return new Promise((resolve, reject) => {
      api
        .put(`company/${changedCompany.id}/`, changedCompany.data)
        .then((company) => {
          commit("alterCompany", company.data);
          resolve(company.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  clearSwd({ commit }, companyId) {
    commit("clearSWD", companyId);
  },
  updateSwd({ commit }, swd) {
    commit("updateSWD", swd);
  },
};

const mutations = {
  setAllUsers(state, users) {
    state.userList = users;
  },
  addUser(state, user) {
    state.userList.results.splice(0, 0, user);
  },
  alterUser(state, user) {
    const record = state.userList.results.findIndex((element) => {
      return element.id == user.id;
    });
    state.userList.results[record] = user;
  },
  setUserSelect(state, users) {
    state.userSelect = users.results;
  },
  setAllCompanies(state, companies) {
    state.companyList = companies.results; //for select in user management
  },
  setCompanies(state, companies) {
    state.companies = companies; //for companies tab in dashboard
  },
  addCompany(state, company) {
    state.companyList.push(company); //not necessery, will update a search
    state.companies.results?.push(company);
  },
  alterCompany(state, company) {
    const record = state.companies.results.findIndex((element) => {
      return element.id == company.id;
    });
    state.companies.results[record] = company;
  },
  setLoading(state, flag) {
    state.loading = flag;
  },
  clearSWD(state, id) {
    const record = state.companies.results.findIndex((element) => {
      return element.id == id;
    });
    state.companies.results[record].kodsw = [];
  },
  updateSWD(state, payload) {
    const record = state.companies.results.findIndex((element) => {
      return element.id == payload.companyId;
    });
    state.companies.results[record].kodsw.push(payload.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
