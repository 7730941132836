import { createRouter, createWebHashHistory } from "vue-router";
import ResetPassword from "@/components/resetPassword/ResetPassword.vue";
import RetrievingPassword from "@/components/resetPassword/RetrievingPassword.vue";
import Login from "@/components/authorizationModule/Login.vue";
import Register from "@/components/authorizationModule/Register.vue";
import LandingPage from "../views/LandingPage.vue";
import ImportView from "../views/ImportView.vue";
import ProfileView from "../views/ProfileView";
// import ChartsView from '../views/ChartsView.vue';
import DashboardView from "../views/DashboardView";
import PrivacyPolicy from "../components/informations/PrivacyPolicy";
import TermsServices from "../components/informations/TermsServices";
// import BugRaport from "../views/BugRaport";
import QandA from "../views/QandA";
import auth from "@/auth";
import api from "../services/api";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
    meta: { layout: "MainLayout" },
  },
  {
    path: "/logowanie",
    name: "Login",
    component: Login,
    meta: { layout: "LoginLayout", title: "- logowanie" },
  },
  {
    path: "/resetpassword/:uid/:user_token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { layout: "LoginLayout", title: "- resetowanie" },
  },
  {
    path: "/odzyskanie_hasla",
    name: "RetrievingPassword",
    component: RetrievingPassword,
    meta: { layout: "LoginLayout", title: "- link" },
  },
  {
    path: "/rejestracja",
    name: "Register",
    component: Register,
    meta: { layout: "LoginLayout", title: "- rejestracja" },
  },
  {
    path: "/import",
    name: "Import",
    component: ImportView,
    meta: { title: "- import" },
  },
  {
    path: "/profil",
    name: "Profile",
    component: ProfileView,
    meta: { title: "- profil" },
  },
  // {
  //   path: "/wskazniki",
  //   name: "Wskaźniki",
  //   component: ChartsView,
  //   meta: { title: "- wskaźniki" },
  // },

  {
    path: "/panel",
    name: "Dashboard",
    component: DashboardView,
    meta: { title: "- panel zarządczy" },
  },
  {
    path: "/polityka",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { layout: "MainLayout", title: "- polityka prywatności" },
  },
  // {
  //   path: "/zgloszenia",
  //   name: "BugRaport",
  //   component: BugRaport,
  //   meta: { layout: "MainLayout", title: "- panel zgłoszeń" },
  // },
  {
    path: "/Q&A",
    name: "QAList",
    component: QandA,
    meta: { layout: "MainLayout", title: "- najczęściej zadawane pytania" },
  },
  {
    path: "/regulamin",
    name: "TermsServices",
    component: TermsServices,
    meta: { layout: "MainLayout", title: "- regulamin serwisu" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let confirmProfile = false;

  auth.checkAuth();
  const unauthCanAccess = [
    "Login",
    "Register",
    "ResetPassword",
    "RetrievingPassword",
    "LandingPage",
    "PrivacyPolicy",
    "TermsServices",
    "BugRaport",
    "QAList",
  ];
  if (!unauthCanAccess.includes(to.name) && !auth.user.authenticated) {
    //when user tries to enter without signing up
    next({ name: "Login" });
  } else if (to.name == "Login" && auth.user.authenticated) {
    next({ name: "Import" });
  } else if (to.path == "/checkRedirection") {
    //after signing up, checks if user changed password
    api.get(`users/current_user/`).then((response) => {
      console.log(response);
      confirmProfile = response.data.chenge_pass;
      console.log(confirmProfile);

      if (!confirmProfile) {
        next({ name: "Profile" });
      } else {
        console.log("red");
        next({ name: "Import" });
      }
    });
  } else next();
});

const DEFAULT_TITLE = "Raport Kadr";
router.afterEach((to) => {
  document.title = DEFAULT_TITLE + (to.meta.title ? " " + to.meta.title : "");
});

export default router;
