<template>
  <div>
    <v-card>
      <v-dialog v-model="loading" hide-overlay persistent>
        <v-card color="primary">
          <v-card-text style="color: white">
            Ładowanie...
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card-text>
        <v-row class="justify-end ma-4">
          <report-button @show-message="showError" />
        </v-row>
        <div class="my-5">
          <v-toolbar dense floating style="width: 30%" class="pr-0">
            <v-text-field
              v-model="searchField"
              hide-details
              prepend-icon="mdi-magnify"
              single-line
              label="Wyszukaj"
              @input="filter()"
              :hint="numOfFounded"
            /> </v-toolbar
          ><br />
          Znaleziono {{ userList.count }} rekordów
        </div>
        <v-table density="compact">
          <thead>
            <tr>
              <th class="text-left">E-mail</th>
              <th class="text-left">Nazwa firmy</th>
              <th class="text-left">Regon</th>
              <th class="text-right">Opcje</th>
            </tr>
          </thead>
          <tbody class="pb-6">
            <user-form id="newUser" :user="null" />
            <template v-for="user in userList.results">
              <tr
                :key="user.id"
                v-if="!editedRecord || editedRecord.id != user.id"
              >
                <td>{{ user.email }}</td>
                <template v-if="user.company_users">
                  <td>{{ user.company_users.name }}</td>
                  <td>{{ user.company_users.regon }}</td>
                </template>
                <td v-else colspan="2">Brak przypisanej firmy</td>
                <td class="text-right">
                  <v-tooltip anchor="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        color="primary"
                        style="cursor: pointer"
                        dark
                        v-bind="props"
                        @click="editedRecord = user"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>edytuj</span>
                  </v-tooltip>
                  <v-tooltip anchor="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        color="primary"
                        style="cursor: pointer"
                        dark
                        v-bind="props"
                        @click="sendMail(user.email)"
                        class="ml-4"
                      >
                        mdi-email-send-outline
                      </v-icon>
                    </template>
                    <span>wyślij maila z nowym hasłem</span>
                  </v-tooltip>
                  <v-tooltip anchor="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        color="primary"
                        style="cursor: pointer"
                        dark
                        v-bind="props"
                        class="ml-4"
                        @click="showFiles(user)"
                      >
                        mdi-file-multiple-outline
                      </v-icon>
                    </template>
                    <span>zobacz pliki</span>
                  </v-tooltip>
                </td>
              </tr>
              <user-form
                :key="user.id"
                v-else
                :user="editedRecord"
                @edit-success="finishedEdit"
                @edit-cancel="finishedEdit"
              />
            </template>
          </tbody>
        </v-table>
        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col :cols="calculateCols(userList.count)">
                <v-container class="max-width">
                  <v-pagination
                    v-model="page"
                    :length="pageNum(userList.count)"
                    class="my-4"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card-text>
    </v-card>

    <custom-modal
      :flag="flag"
      @close-modal="
        () => {
          message = '';
          flag = false;
        }
      "
    >
      <template v-slot:message>
        <div class="text-justify">{{ message }}</div>
      </template>
    </custom-modal>
    <v-dialog v-model="displayFiles">
      <file-list :params="fileParams" :options="'all'"></file-list>
      <v-btn color="primary" block @click="displayFiles = false">Zamknij</v-btn>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../services/api";
import { mapState, mapActions } from "vuex";
import UserForm from "./UserForm.vue";
import CustomModal from "../layoutComponents/CustomModal.vue";
import FileList from "../importModule/FileList.vue";
import ReportButton from "./ReportButton.vue";

export default {
  components: { UserForm, CustomModal, FileList, ReportButton },
  name: "ManageUsers",
  data() {
    return {
      editedRecord: null,
      flag: false,
      message: "",
      page: 1,
      size: 20,
      searchField: "",
      displayFiles: false,
      fileParams: {},
    };
  },
  watch: {
    page(newVal) {
      this.filter(newVal);
    },
  },
  methods: {
    ...mapActions("users", ["getAllUsers", "getAllCompanies"]),
    sendMail(userMail) {
      api
        .post(`/users/reset_password/`, { email: userMail })
        .then((response) => {
          console.log("Zresetowano hasło! ", response.data);
          this.message = `Do użytkownika ${userMail} wysłano maila z nowymi poświadczeniami`;
          this.flag = true;
          return false;
        })
        .catch((err) => {
          console.log("Wystąpił błąd przy próbie wysłania poświadczeń! ", err);
          this.message = "Wystąpił błąd przy próbie wysłania poświadczeń!";
          this.flag = true;
          return false;
        });
    },
    finishedEdit() {
      this.editedRecord = null;
    },
    pageNum(numOfPages) {
      if (numOfPages) return Math.ceil(numOfPages / this.size);
    },
    calculateCols(numOfPages) {
      return Math.ceil(numOfPages / this.size) < 4
        ? Math.ceil(numOfPages / this.size)
        : 4;
    },
    filter(page = null) {
      let params = { size: this.size, search: this.searchField };
      page ? (params["page"] = this.page) : (this.page = 1);
      this.getAllUsers({ params }).catch((error) => {
        console.log(error);
      });
    },
    showFiles(user) {
      // this.fileParams = { user_id: user.id };
      this.fileParams = { company: user.company_users.id };
      this.displayFiles = true;
    },
    showError(error) {
      this.message = error.text;
      this.flag = true;
    },
  },
  computed: {
    ...mapState({
      userList: (state) => state.users.userList,
      loading: (state) => state.users.loading,
    }),
    numOfFounded() {
      return `Znaleziono ${this.userList.count}`;
    },
  },
  created() {
    let params = { size: this.size };
    this.getAllUsers({ params }).catch((error) => {
      console.log(error);
    });
    // this.getAllCompanies().catch((error) => {
    //   console.log(error);
    // });
  },
};
</script>

<style>
.vs__dropdown-menu {
  z-index: 200px;
}
</style>
