<template>
  <tr
    :style="
      swd
        ? 'background: rgba(var(--v-border-color), var(--v-hover-opacity))'
        : ''
    "
  >
    <td>
      <v-text-field
        :label="swd ? 'Kod SWD' : 'Dodaj nowy kod SWD'"
        variant="underlined"
        v-model="kod_sw"
        hide-details="auto"
      />
    </td>
    <td>
      <v-text-field
        label="Oddział Wojewódzki"
        variant="underlined"
        v-model="oddzial_ow"
        hide-details="auto"
      />
    </td>

    <td class="text-right">
      <v-tooltip anchor="top">
        <template v-slot:activator="{ props }">
          <v-icon
            color="primary"
            style="cursor: pointer"
            dark
            v-bind="props"
            @click="confirmChanges()"
          >
            mdi-content-save
          </v-icon>
        </template>
        <span>zapisz</span>
      </v-tooltip>
      <v-tooltip anchor="top" v-if="swd">
        <template v-slot:activator="{ props }">
          <v-icon
            color="primary"
            style="cursor: pointer"
            dark
            v-bind="props"
            @click="$emit('finishEdit')"
            class="ml-4"
          >
            mdi-close-outline
          </v-icon>
        </template>
        <span>anuluj</span>
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SwdForm",
  props: ["swd"],
  data() {
    return {
      kod_sw: "",
      oddzial_ow: "",
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser.currentUser,
    }),
  },
  methods: {
    ...mapActions("kodsw", ["addKodsw", "editKodsw"]),
    confirmChanges() {
      this.$emit("loading", true);
      if (this.currentUser.company_users === null) {
        this.$emit("loading", false);
        this.$emit("updateSnackbar", {
          text: "Brak przypisanej firmy.",
          color: "error",
        });
        return true;
      }

      const dataToSend = {
        kod_sw: this.kod_sw.trim(),
        oddzial_ow: this.oddzial_ow.trim(),
        company_sw_id: this.currentUser.company_users.id,
      };
      if (this.swd == null) {
        this.addKodsw(dataToSend)
          .then(() => {
            this.kod_sw = "";
            this.oddzial_ow = "";
            this.$emit("loading", false);
            console.log("emit");
            this.$emit("updateSnackbar", {
              color: "success",
              text: "Dodano nowy kod SWD.",
            });
          })
          .catch((err) => {
            this.$emit("loading", false);
            this.$emit("updateSnackbar", {
              color: "error",
              text: "Wystąpił błąd.",
            });
            console.log(err.status, " ---- ", err.data);
          });
      } else {
        this.editKodsw({ id: this.swd.id, data: dataToSend })
          .then(() => {
            this.$emit("finishEdit");
            this.$emit("loading", false);
            this.$emit("updateSnackbar", {
              color: "success",
              text: "Zedytowano kod SWD.",
            });
          })
          .catch((err) => {
            this.$emit("loading", false);
            this.$emit("updateSnackbar", {
              color: "error",
              text: "Wystąpił błąd.",
            });
            console.log(err.status, " ---- ", err.data);
          });
      }
    },
  },
  created() {
    if (this.swd) {
      const copy = JSON.parse(JSON.stringify(this.swd));
      this.kod_sw = copy.kod_sw;
      this.oddzial_ow = copy.oddzial_ow;
    }
  },
};
</script>

<style lang="scss" scoped>
.animated {
  animation-fill-mode: both;
}
</style>
