<template>
  <custom-select
    :options="companyList"
    label="regon"
    v-model="company"
    placeholder="wyszukaj po numerze regon..."
    @input="filterCompanies"
    style="max-width: 500px"
  >
    <!-- eslint-disable-next-line vue/no-unused-vars  -->
    <template #no-options="{ search, searching, loading }">
      Nie znaleziono - wpisz co najmniej 3 cyfry.
    </template>
  </custom-select>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "CompanySelect",
  props: ["defCompany"],
  data() {
    return {
      company: null,
    };
  },
  methods: {
    ...mapActions("users", ["getAllCompanies", "resetAllCompanies"]),
    filterCompanies(e) {
      let params = { search: e.target.value };
      setTimeout(() => {
        e.target.value && e.target.value.length > 2
          ? this.getAllCompanies({ params }).catch((error) => {
              console.log(error);
            })
          : this.resetAllCompanies();
      }, 500);
    },
  },

  computed: {
    ...mapState({
      companyList: (state) => state.users.companyList,
    }),
  },
  created() {
    if (this.defCompany) this.company = this.defCompany;
  },
};
</script>

<style></style>
