<template>
  <div>
    <v-dialog v-model="isSrkModalVisible" persistent>
      <v-card>
        <v-card-text class="text-center"
          >Jestem podmiotem zobowiązanym ustawowo do stosowania standardu
          rachunku kosztów zgodnie z Rozporządzeniem Ministra Zdrowia z dnia 26
          października 2020 r.<br />w sprawie zaleceń dotyczących standardu
          rachunku kosztów u świadczeniodawców</v-card-text
        >
        <v-card-text>
          <v-radio-group v-model="isSrkValue">
            <v-radio label="Tak" :value="true"></v-radio>
            <v-radio label="Nie" :value="false"></v-radio>
          </v-radio-group>
        </v-card-text>
        <div class="text-center">
          <v-btn
            color="primary"
            rounded
            dark
            class="mr-2 mb-4"
            @click="saveIsSrkChoice"
            >Potwierdź</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <custom-modal-without-close :flag="flag" v-model="dialog"
      ><template v-slot:message>
        <div class="text-center">
          Czy dane za wrzesień obejmują podwyżki personelu, zgodnie z ustawą
          dnia 26 maja 2022 r. o sposobie ustalania najniższego wynagrodzenia
          zasadniczego niektórych pracowników zatrudnionych w podmiotach
          leczniczych oraz niektórych innych ustaw (Dz. U. z 2022 r., poz.
          1352)?
        </div>
        <v-container fluid>
          <v-radio-group v-model="picked">
            <v-radio label="TAK" value="true"></v-radio>
            <v-radio label="NIE" value="false"></v-radio>
          </v-radio-group>
        </v-container>
        <v-col cols="12">
          <v-textarea v-model="notes" color="teal">
            <template v-slot:label>
              <div>Uwagi <small>(opcjonalnie)</small></div>
            </template>
          </v-textarea>
          <div class="text-center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mr-2"
              @click="[sendUpdateInfo(), $emit('closeModal')]"
            >
              ZAPISZ I ZAMKNIJ
            </v-btn>
          </div>
        </v-col>
      </template>
    </custom-modal-without-close>
    <v-card elevation="1" class="ma-16 mt-md-16 mr-md-16 mr-2 ml-md-16 ml-24">
      <v-card-title>Import danych</v-card-title>
      <v-card-text>
        Zakładka pozwalająca na import pliku w formacie .xls lub .xlsx oraz
        pobranie jego szablonu. Po wgraniu załącznika podlega on walidacji i
        zwracana jest informacja o nieprawidłowych wartościach.
        <import-form
          :company="currentUser.company_users"
          :errorReport="errorReport"
          :userId="currentUser.id"
          @update-list="updateFileList"
          @updateFileIsValidated="handleFilesIsValidated"
          @updateFileIsValidatedStatus="handleFilesIsValidatedStatus"
          @updateFileIsImported="handleFilesIsImported"
          @updateStatusID="handleStatusID"
        />
      </v-card-text>
      <v-card-actions>
        <template-button :isSrkValue="isSrkValue" />
      </v-card-actions>
    </v-card>
    <file-list
      v-if="currentUser.company_users"
      class="ma-16 mt-md-16 mr-md-16 mr-2 ml-md-16 ml-24"
      :params="{
        company: currentUser.company_users?.id || null,
        // is_verify: true,
        // is_imported: true,
      }"
      :options="'all'"
      :errorReport="errorReport"
      :fileIsValidated="fileIsValidated"
      :fileIsImported="fileIsImported"
      :status="status"
      :statusID="statusID"
      :statusImport="statusImport"
      ref="files"
      @updateErrorReport="updateErrorReport"
      @updateFileIsImportedStatus="handleFilesIsImportedStatus"
      @updateFileIsImported="handleFilesIsImported"
    ></file-list>
  </div>
</template>

<script>
import api from "../services/api";
import ImportForm from "../components/importModule/ImportForm.vue";
import TemplateButton from "../components/importModule/TemplateButton.vue";
import FileList from "../components/importModule/FileList.vue";
import CustomModalWithoutClose from "../components/layoutComponents/CustomModalWithoutClose.vue";
import { mapState } from "vuex";

export default {
  name: "HomeView",

  data() {
    return {
      notes: "",
      message: [],
      flag: true,
      picked: null,
      company: null,
      companyId: null,
      dialog: false,
      isSrkModalVisible: false,
      isSrkValue: null,
      errorReport: [],
      is_staff: null,
      fileIsValidated: null,
      fileIsImported: null,
      status: "pending",
      statusID: null,
      statusImport: "pending",
    };
  },
  methods: {
    updateFileList() {
      this.$refs.files.getFiles({
        company: this.currentUser.company_users.id,
        is_verify: true,
      });
    },
    handleFilesIsValidated(value) {
      this.fileIsValidated = value;
    },
    handleFilesIsImported(value) {
      this.fileIsImported = value;
    },
    handleFilesIsValidatedStatus(status) {
      this.status = status;
    },
    handleStatusID(id) {
      this.statusID = id;
    },
    handleFilesIsImportedStatus(value) {
      this.statusImport = value;
    },
    updateErrorReport(newState) {
      this.errorReport = newState;
    },
    sendUpdateInfo() {
      this.$emit("loading", true);
      let paramsToEndpoint = this.companyId;
      let dataToSend = {
        isRaise: this.picked,
        raiseComment: this.notes,
      };
      api
        .patch(`/company/${paramsToEndpoint}/`, dataToSend)
        .then(() => {})
        .catch(() => {});
      this.dialog = false;
    },
    checkIsSrkField() {
      api
        .get(`company/${this.companyId}/`)
        .then((response) => {
          if (response.data.is_srk === null || undefined) {
            this.isSrkModalVisible = true;
          } else {
            this.isSrkValue = response.data.is_srk;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    saveIsSrkChoice() {
      const isSrkData = { is_srk: this.isSrkValue };
      api
        .put(`company/${this.companyId}/`, isSrkData)
        .then(() => {
          this.isSrkModalVisible = false;
          this.isSrkValue = isSrkData.is_srk;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser.currentUser,
    }),
  },
  mounted() {
    api
      .get(`/users/current_user/`)
      .then((response) => {
        this.company = response.data;
        this.companyId = response.data.company_users.id;
        this.is_staff = response.data.is_staff;
        if (this.company.company_users.isRaise === null) {
          this.dialog = true;
        } else {
          return null;
        }
      })
      .then(() => this.checkIsSrkField())
      .catch(() => {});
  },
  components: {
    TemplateButton,
    FileList,
    ImportForm,
    CustomModalWithoutClose,
  },
};
</script>

<style lang="scss" scoped></style>
