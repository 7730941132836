import api2 from "../../services/api2";
import api from "../../services/api";

const state = {
  questions: [],
  loading: false,
};

const getters = {
  questions: (state) => {
    return state.questions;
  },
};

const actions = {
  getQuestions({ commit }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      api2
        .get(`qanda/`)
        .then((response) => {
          commit("setQuestions", response);
          commit("setLoading", false);
          resolve(response);
        })
        .catch((err) => {
          commit("setLoading", false);
          console.log(err);
          reject(err);
        });
    });
  },
  postQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api2
        .post(`qanda/`, payload)
        .then((response) => {
          commit("addQuestion", response);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  putQuestion({ commit }, [id, payload]) {
    return new Promise((resolve, reject) => {
      api
        .put(`qanda/${id}/`, payload)
        .then((response) => {
          commit("updateQuestion", response);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

const mutations = {
  setQuestions(state, response) {
    state.questions = response.data;
  },
  addQuestion(state, response) {
    state.questions.push(response.data);
  },
  setLoading(state, flag) {
    state.loading = flag;
  },
  updateQuestion(state, response) {
    let index = state.questions.findIndex((el) => el.id === response.data.id);
    state.questions[index] = response.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
