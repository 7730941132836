<template>
  <div id="deflayout">
    <v-navigation-drawer v-model="drawer">
      <v-list>
        <v-list-item
          :prepend-avatar="require('./assets/logo_bez_tla.png')"
          title="Raport Kadr"
          subtitle="AOTMiT"
          @click="$router.push('/')"
        ></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          :title="item.title"
          :value="item.title"
          @click="goTo(item.link)"
          class="text-grey-darken-2"
          :class="$route.path === item.link ? 'v-list-item--active' : ''"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-btn
      @click="drawer = true"
      v-if="$vuetify.display.mobile"
      variant="outlined"
      icon
      color="primary"
      class="menuIcon"
    >
      <v-icon>mdi-format-list-bulleted-square</v-icon>
    </v-btn>

    <v-list class="titleBanner" v-if="$vuetify.display.mobile && !drawer">
      <v-list-item
        :prepend-avatar="require('./assets/logo_bez_tla.png')"
        title="Raport Kadr"
        subtitle="AOTMiT"
      ></v-list-item>
    </v-list>

    <div id="content" class="mb-10 footerMargin"><slot /></div>
    <custom-footer />
  </div>
</template>

<script>
import auth from "@/auth";
import { mapState, mapActions } from "vuex";
import CustomFooter from "./components/layoutComponents/CustomFooter.vue";

export default {
  name: "DefLayout",
  data() {
    return {
      items: [
        {
          title: "Import danych",
          icon: "mdi-application-import",
          link: "/import",
        },
        // {
        //   title: "Weryfikacja",
        //   icon: "mdi-pencil-box-multiple-outline",
        //   link: "/verify",
        // },
        { title: "Profil", icon: "mdi-account", link: "/profil" },
        // {
        //   title: "Wskaźniki",
        //   icon: "mdi-chart-areaspline",
        //   link: "/wskazniki",
        // },

        { title: "Wyloguj", icon: "mdi-logout", link: null },
      ],
      drawer: null,
    };
  },
  methods: {
    ...mapActions("currentUser", ["getCurrentUser"]),
    goTo(link) {
      if (!link) {
        auth.logout();
      } else {
        this.$router.push({ path: link });
      }
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser.currentUser,
      confirm: (state) => state.currentUser.isConfirm,
    }),

    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  created() {
    if (auth.checkAuth())
      this.getCurrentUser().then((res) => {
        if (res.is_staff) {
          this.items.splice(this.items.length - 1, 0, {
            title: "Panel zarządczy",
            icon: "mdi-monitor-dashboard",
            link: "/panel",
          });
        }
      });
    // if (auth.checkAuth())
    //   this.getCurrentUser().then((res) => {
    //     if (res.is_staff) {
    //       this.items.splice(this.items.length - 2, 0, {
    //         title: "Wskaźniki",
    //         icon: "mdi-chart-areaspline",
    //         link: "/wskazniki",
    //       });
    //     }
    //   });
  },
  components: { CustomFooter },
};
</script>

<style lang="scss" scoped>
.menuIcon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.titleBanner {
  width: 200px;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;
}
</style>
