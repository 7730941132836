<template>
  <v-card>
    <v-card-text>
      <div v-for="type in Object.keys(qTypes)" :key="type" class="mb-5">
        <h4>{{ qTypes[type] }}</h4>
        <span v-for="(item, i) in sortedQuestions[type]" :key="item.id">
          <qna-form :data="item" :index="i + 1" />
        </span>
      </div>
    </v-card-text>
    <v-dialog v-model="loading" hide-overlay persistent>
      <v-card color="primary">
        <v-card-text style="color: white">
          Ładowanie...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import QnaForm from "./QnaForm.vue";
export default {
  components: { QnaForm },
  name: "QnaComponent",
  data() {
    return {
      qTypes: {
        tech: "TECHNICZNE",
        ogol: "OGÓLNE",
        list: "ARKUSZ: Lista OPK 3",
        uop: "ARKUSZ: Tabela-UoP, dzieło, zlecenie 3",
        pod: "ARKUSZ: Tabela-podwykonawstwo medyczne 4",
        miej: "ARKUSZ: Miejsca pracy - OPK 6",
      },
    };
  },
  computed: {
    ...mapState({
      QAList: (state) => state.questions.questions,
      loading: (state) => state.questions.loading,
    }),
    sortedQuestions() {
      let sorted = {};
      Object.keys(this.qTypes).forEach((key) => (sorted[key] = []));
      this.QAList.forEach((element) => {
        sorted[element.type].push(element);
      });
      return sorted;
    },
  },
  methods: {
    ...mapActions("questions", ["getQuestions"]),
  },
  created() {
    this.getQuestions();
  },
};
</script>

<style></style>
