<template>
  <div>
    <v-container fluid style="padding-top: 5%">
      <v-row align="center" justify="center" dense>
        <v-col>
          <v-card class="ma-auto pa-4" max-width="500" max-height="650">
            <v-card-title class="align-center justify-center">
              <v-img
                @click="$router.push('/')"
                :src="require('../../assets/logo_bez_tla.png')"
                class="my-3"
                contain
                height="115"
                style="cursor: pointer"
              />
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="pb-10">
                      <div style="text-align: center">
                        <h1 class="font-weight-light">Nie pamiętasz hasła?</h1>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" class="pa-0">
                      <div>
                        Wpisz adres e-mail konta, do którego chcesz odzyskać
                        dostęp
                      </div>
                      <v-text-field
                        v-model="credentials.emailToRetreivingPassword"
                        label="Email"
                        outlined
                        :append-icon="'mdi-email'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                rounded="pill"
                color="white"
                style="width: 80%; background: #17728d"
                @click="retreivingPassword()"
                :disabled="!isButtonDisabled"
              >
                Wyślij</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <custom-snackbar :snackbar="snackbar" @clear="updateSnackbar()" />
    </v-container>
  </div>
</template>

<script>
import api from "../../services/api.js";
import CustomSnackbar from "../layoutComponents/CustomSnackbar.vue";

export default {
  components: { CustomSnackbar },
  name: "RetrievingPassword",
  data() {
    return {
      credentials: {
        emailToRetreivingPassword: "",
      },
      snackbar: {
        open: false,
        text: "",
        color: "",
      },
    };
  },
  computed: {
    isButtonDisabled() {
      return this.credentials.emailToRetreivingPassword;
    },
  },
  methods: {
    updateSnackbar(
      msg = {
        open: false,
        text: "",
        color: "",
      }
    ) {
      this.snackbar = msg;
    },
    retreivingPassword() {
      this.$emit("loading", true);
      let dataToSend = {
        email: this.credentials.emailToRetreivingPassword,
      };
      api
        .post(`/password_reset_mail/ `, dataToSend)
        .then((response) => {
          if (response.status === 200) {
            this.updateSnackbar({
              open: true,
              color: "success",
              text: response?.data,
            });
          }
          setTimeout(() => {
            this.$router.push("/#");
          }, 1500);
        })
        .catch((response) => {
          this.updateSnackbar({
            open: true,
            color: "error",
            text: response?.data,
          });
        });
    },
  },
};
</script>

<style></style>
