<template>
  <v-card>
    <v-card-title
      >Lista plików {{ title[options] }}
      <span v-if="options === 'test'">{{ userName }}</span></v-card-title
    >
    <v-card-text>
      <div v-if="loading">
        <v-progress-linear indeterminate color="primary" class="mb-0" />
      </div>
      <v-table v-else density="compact">
        <thead>
          <tr>
            <th class="text-left">Nazwa pliku</th>
            <th class="text-left">Okres rozliczeniowy</th>
            <th class="text-left">Kod świadczeniodawcy</th>
            <th class="text-left">Data utworzenia pliku</th>
            <th class="text-left">Data walidacji pliku</th>
            <th class="text-left">Status</th>
            <th class="text-right">Opcje</th>
            <th class="text-left">Akcje</th>
          </tr>
        </thead>
        <tbody class="pb-6" v-if="fileList.length">
          <tr v-for="file in fileList" :key="file.id">
            <td>{{ fileName(file.file) }}</td>
            <td>{{ file.month }}/{{ file.year }}</td>
            <td>{{ file.nfz_number }}</td>
            <td style="white-space: nowrap">{{ dispData(file.created_at) }}</td>
            <td style="white-space: nowrap">{{ dispData(file.updated_at) }}</td>
            <td v-if="options == 'import'">
              {{ file.is_verify ? "zwalidowany" : "niezwalidowany" }}
            </td>
            <td v-else style="white-space: nowrap">
              <v-tooltip anchor="top">
                <template v-slot:activator="{ props }">
                  <div
                    v-bind="props"
                    @click="validateFile(file.id, file.is_verify)"
                    class="btnHover"
                  >
                    Walidacja
                    <v-icon v-if="file.is_verify == true" color="primary" dark>
                      mdi-check-bold
                    </v-icon>

                    <v-icon v-if="file.is_verify == false" color="error" dark>
                      mdi-close
                    </v-icon>
                    <v-icon
                      v-if="file.is_verify == null"
                      color="secondary"
                      dark
                    >
                      mdi-help-circle-outline</v-icon
                    >
                  </div>
                </template>
                <span>kliknij, by sprawdzić status walidacji</span>
              </v-tooltip>
              <v-tooltip anchor="top">
                <template v-slot:activator="{ props }">
                  <div
                    v-bind="props"
                    @click="
                      importData(file.id, file.is_imported);
                      this.chosenFileVerify = file.is_verify;
                    "
                    class="btnHover"
                  >
                    Import
                    <v-icon v-if="file.is_imported" color="primary" dark>
                      mdi-check-bold
                    </v-icon>
                    <v-icon v-if="file.is_imported == false" color="error" dark>
                      mdi-close
                    </v-icon>
                    <v-icon
                      v-if="file.is_imported == null"
                      color="secondary"
                      dark
                    >
                      mdi-help-circle-outline</v-icon
                    >
                  </div>
                </template>
                <span>kliknij, by sprawdzić status importu danych</span>
              </v-tooltip>
            </td>
            <td>
              <!-- <template v-if="options == 'all'">
                <a
                  :href="file.file"
                  target="_blank"
                  class="font-weight-regular"
                  style="color: black"
                  >Pobierz</a
                >
              </template> -->
              <v-btn
                v-if="(file.is_verify === true) & (file.is_imported === true)"
                color="primary"
                variant="text"
                @click="
                  openModalWithDown(file, file.id, file.is_imported);
                  this.chosenFileVerify = file.is_verify;
                "
              >
                Potwierdzenie
              </v-btn>
              <div v-else class="text-center">-</div>
            </td>
            <td>
              <div class="icon-wrapper">
                <v-tooltip anchor="top">
                  <template v-slot:activator="{ props }">
                    <svg-icon
                      v-bind="props"
                      type="mdi"
                      :path="path"
                      @click="exportErrors()"
                      v-if="
                        file.is_verify === false && file.is_imported === false
                      "
                    ></svg-icon>
                  </template>
                  <span>Pobierz raport błędów</span>
                </v-tooltip>
                <v-tooltip anchor="top">
                  <template v-slot:activator="{ props }">
                    <svg-icon
                      v-bind="props"
                      type="mdi"
                      :path="path2"
                      @click="downloadFile(file.id)"
                    ></svg-icon>
                  </template>
                  <span> Pobierz plik</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">Brak przesłanych plików</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>

    <custom-modal :flag="showModal" @close-modal="closeModal">
      <template v-slot:message>
        <div
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <div
            class="text-justify"
            style="margin-bottom: 30px"
            v-html="message"
          ></div>
          <v-btn v-if="showButton" color="primary" text @click="exportErrors()">
            Pobierz raport błędów
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="importDataFromFile(this.idToVerify)"
            v-if="this.verify === true"
          >
            Importuj dane
          </v-btn>
        </div>
      </template>
    </custom-modal>
    <custom-modal :flag="showImportModal" @close-modal="closeImportModal">
      <template v-slot:message>
        <div class="text-justify" v-html="message"></div>
        <div
          class="d-flex justify-space-between"
          style="margin-top: 20px !important"
        >
          <v-btn
            color="primary"
            text
            small
            v-if="this.import"
            @click="confirmImport()"
            >Potwierdź</v-btn
          >
          <v-btn color="primary" text small v-if="this.import" @click="cancel()"
            >Anuluj</v-btn
          >
          <div v-if="!this.import && !this.chosenFileVerify">
            Walidacja nie przebiegła poprawnie, spróbuj ponownie
          </div>

          <v-btn
            color="primary"
            text
            @click="importNewData()"
            v-if="!this.import && this.chosenFileVerify"
          >
            Importuj dane
          </v-btn>
        </div>
      </template>
    </custom-modal>

    <custom-modal
      :flag="showImportModalWithDownload"
      @close-modal="closeImportModalWithDownload"
    >
      <template v-slot:message>
        <div class="text-justify" v-html="message"></div>
        <div
          class="d-flex justify-space-between"
          style="margin-top: 20px !important"
        >
          <v-btn color="primary" text small @click="confirmImportWithDownload()"
            >Potwierdź</v-btn
          >
          <v-btn
            color="primary"
            text
            small
            @click="closeImportModalWithDownload()"
            >Anuluj</v-btn
          >
        </div>
      </template>
    </custom-modal>

    <custom-snackbar :snackbar="snackbar" @clear="updateSnackbar" />
  </v-card>
</template>

<script>
import api from "../../services/api";
import moment from "moment";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  AlignmentType,
  convertMillimetersToTwip,
} from "docx";
import { saveAs } from "file-saver";
import CustomModal from "../layoutComponents/CustomModal.vue";
import CustomSnackbar from "../layoutComponents/CustomSnackbar.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiAlertCircleOutline } from "@mdi/js";
import { mdiFileDownloadOutline } from "@mdi/js";

export default {
  components: { CustomModal, CustomSnackbar, SvgIcon },
  name: "FileList",
  props: [
    "params",
    "options",
    "errorReport",
    "fileIsValidated",
    "status",
    "statusID",
    "statusImport",
    "fileIsImported",
  ],
  data() {
    return {
      fileList: [],
      loading: false,
      title: { all: "firmy użytkownika", import: "firmy" },
      userName: "",
      showModal: false,
      showImportModal: false,
      message: "",
      snackbar: {
        open: false,
        text: "",
        color: "",
      },
      verify: false,
      import: false,
      idToVerify: null,
      is_staff: false,
      is_newest: false,
      chosenFileId: null,
      chosenFileVerify: false,
      path: mdiAlertCircleOutline,
      path2: mdiFileDownloadOutline,
      showButton: false,
      importedFile: null,
      showImportModalWithDownload: false,
      file: null,
    };
  },
  methods: {
    openModalWithDown(file, id, isImported) {
      this.file = file;
      this.showImportModalWithDownload = true;
      this.import = isImported;
      this.chosenFileId = id;
      this.message =
        "Dane zostały zaimportowane poprawnie. Potwierdź ostatecznie poprawność danych.";
    },
    closeImportModalWithDownload() {
      this.showImportModalWithDownload = false;
    },
    downloadDoc(file) {
      let paragraphs = [
        new Paragraph({
          children: [
            new TextRun({
              text:
                `Dziękujemy za przesłane dane. Dane firmy ${file.company.name} o regonie ${file.company.regon} zostały poprawnie zaimportowane.` +
                "\r\n\r\n",
              font: "Calibri",
              bold: false,
              size: 26,
            }),
          ],
          alignment: AlignmentType.JUSTIFIED,
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text:
                `Niniejszy dokument nie stanowi podstawy do potwierdzenia współpracy z AOTMiT w toku postępowania w sprawie zawarcia umowy o udzielanie świadczeń opieki zdrowotnej z NFZ.` +
                "\r\n\r\n",
              font: "Calibri",
              bold: false,
              size: 26,
            }),
          ],
          alignment: AlignmentType.JUSTIFIED,
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Z poważaniem AOTMiT` + "\n\n",
              font: "Calibri",
              bold: false,
              size: 26,
            }),
          ],
          alignment: AlignmentType.JUSTIFIED,
          spacing: { after: 200 },
        }),
      ];

      let doc = new Document({
        sections: [
          {
            properties: {
              page: {
                size: {
                  // height: convertMillimetersToTwip(210),
                  // width: convertMillimetersToTwip(297)
                },
                margin: {
                  top: convertMillimetersToTwip(15),
                  right: convertMillimetersToTwip(15),
                  bottom: convertMillimetersToTwip(15),
                  left: convertMillimetersToTwip(15),
                },
              },
            },
            children: paragraphs,
          },
        ],
      });
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "Potwierdzenie importu pliku" + ".doc");
      });
    },
    updateSnackbar(
      msg = {
        open: false,
        text: "",
        color: "",
      }
    ) {
      this.snackbar = msg;
    },
    getFiles(params) {
      this.loading = true;
      let endpoint = this.options == "import" ? "fileupload/" : "allfiles/";

      return new Promise((resolve, reject) => {
        api
          .get(endpoint, { params })
          .then((response) => {
            resolve(response.data);
            this.fileList = response.data.results;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            reject(err);
          });
      });
    },
    fileName(file) {
      return JSON.stringify(file).split("/").pop().replace('"', "");
    },
    confirmImport() {
      api
        .get(`fileupload/${this.chosenFileId}/send_to_final_accept`)
        .then(() => {
          this.showImportModal = false;
          this.updateSnackbar({
            open: true,
            text: `Dane zostały wysłane`,
            color: "success",
          });
        })
        .catch((err) => console.log(err));
    },
    confirmImportWithDownload() {
      api
        .get(`fileupload/${this.chosenFileId}/send_to_final_accept`)
        .then(() => {
          this.showImportModalWithDownload = false;
          this.updateSnackbar({
            open: true,
            text: `Dane zostały wysłane`,
            color: "success",
          });
          let paragraphs = [
            new Paragraph({
              children: [
                new TextRun({
                  text:
                    `Dziękujemy za przesłane dane. Dane firmy ${this.file.company.name} o regonie ${this.file.company.regon} zostały poprawnie zaimportowane.` +
                    "\r\n\r\n",
                  font: "Calibri",
                  bold: false,
                  size: 26,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text:
                    `Niniejszy dokument nie stanowi podstawy do potwierdzenia współpracy z AOTMiT w toku postępowania w sprawie zawarcia umowy o udzielanie świadczeń opieki zdrowotnej z NFZ.` +
                    "\r\n\r\n",
                  font: "Calibri",
                  bold: false,
                  size: 26,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Z poważaniem AOTMiT` + "\n\n",
                  font: "Calibri",
                  bold: false,
                  size: 26,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
              spacing: { after: 200 },
            }),
          ];

          let doc = new Document({
            sections: [
              {
                properties: {
                  page: {
                    size: {
                      // height: convertMillimetersToTwip(210),
                      // width: convertMillimetersToTwip(297)
                    },
                    margin: {
                      top: convertMillimetersToTwip(15),
                      right: convertMillimetersToTwip(15),
                      bottom: convertMillimetersToTwip(15),
                      left: convertMillimetersToTwip(15),
                    },
                  },
                },
                children: paragraphs,
              },
            ],
          });
          Packer.toBlob(doc).then((blob) => {
            saveAs(blob, "Potwierdzenie importu pliku" + ".doc");
          });
        })
        .catch((err) => console.log(err));
    },
    cancel() {
      this.showImportModal = false;
    },
    downloadFile(id) {
      api
        .get(`files/${id}`, { is_final: true })
        .then((data) => {
          let fileName = data.data.file.split("/").pop();
          let anchor = document.createElement("a");
          anchor.href = data.data.file;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
        })
        .catch((err) => {
          this.updateSnackbar({
            open: true,
            text: `${err.data.detail} Plik może zostać pobrany jedynie przez użytkownika, który go wysłał.`,
            color: "error",
          });
        });
    },

    dispData(data) {
      return moment(data).format("DD-MM-YYYY");
    },
    importDataFromFile(id) {
      this.$emit("updateFileIsImported", "updated");
      this.showModal = false;

      api
        .get(`fileupload/${id}/import_data_from_file/ `)
        .then((res) => {
          if (res) {
            const intervalId = setInterval(() => {
              api
                .get(`task-status/${res.data.task_id}/`)
                .then((response) => {
                  this.$emit(
                    "updateFileIsImportedStatus",
                    response.data.status
                  );

                  // Sprawdź, czy status jest "completed"
                  if (response.data.status === "completed") {
                    clearInterval(intervalId); // Zakończ interwał
                    this.$emit("updateFileIsImported", null);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  clearInterval(intervalId); // Zakończ interwał w przypadku błędu
                });
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.showModal = false;
        });
    },
    validateFile(id, isVerify) {
      this.showModal = true;
      this.verify = isVerify;
      this.idToVerify = id;
      this.showButton = this.shouldShowButton;
      api
        .get(`fileupload/${id}/get_bug_report/`)
        .then((res) => {
          this.loading = false;
          this.message = this.formattedErrorReport(res.data);
          this.$emit("updateErrorReport", res.data);
        })
        .catch((err) => {
          this.loading = false;
          this.message = "Wystąpił błąd przy walidacji pliku.";
          console.log(err.status, " ---- ", err.data);
        });
    },
    formattedErrorReport(response) {
      if (!Array.isArray(response)) return response;
      return response
        .map((item) => {
          let msg = "";
          if (item.sheet_name) {
            msg = `W arkuszu o nazwie <strong>${item.sheet_name}</strong> wystąpił błąd `;
          } else msg = "Wystąpił błąd ";
          msg += `<strong>${item.desc}</strong>. Nieprawidłowa wartość`;
          if (item.false_val) msg += ` <strong>${item.false_val}</strong>`;
          if (item.column_name)
            msg += ` w kolumnie <strong>${item.column_name}</strong>`;
          if (item.row_number)
            msg += ` w wierszu <strong>${item.row_number}</strong>`;
          if (item.true_val)
            msg += ` powinna być zastąpiona <strong>${item.true_val}</strong>`;
          return msg + ".";
        })
        .join("<br/>");
    },
    exportErrors() {
      var XLSX = require("xlsx");
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(
        [
          {
            desc: "OPIS BŁĘDU",
            sheet_name: "NAZWA ARKUSZA",
            column_name: "NAZWA KOLUMNY",
            row_number: "NAZWA WIERSZA",
            false_val: "NIEPRAWIDŁOWA WARTOŚĆ",
            true_val: "OCZEKIWANA WARTOŚĆ",
          },
        ].concat(this.errorReport),
        {
          header: [
            "desc",
            "sheet_name",
            "column_name",
            "row_number",
            "false_val",
            "true_val",
          ],
          skipHeader: true,
        }
      );
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, "Raport błędów" + fileExtension);
    },
    importData(id, isImported) {
      this.showImportModal = true;
      this.import = isImported;
      this.chosenFileId = id;
      this.message =
        "Dane zostały zaimportowane poprawnie. Potwierdź ostatecznie poprawność danych.";
      // api
      //   .get(`fileupload/${id}/data_import/`)
      //   .then(() => {
      //     this.loading = false;
      //     this.message = "Import danych zakończył się sukcesem.";
      //   })
      //   .catch((err) => {
      //     this.loading = false;
      //     this.message = "Wystąpił błąd przy imporcie pliku.";
      //     console.log(err.status, " ---- ", err.data);
      //   });
    },
    importNewData() {
      this.$emit("updateFileIsImported", "updated");
      this.showImportModal = false;
      this.updateSnackbar({
        open: true,
        text: "Dane zostały wysłane do importu.",
        color: "success",
      });

      api
        .get(`fileupload/${this.chosenFileId}/import_data_from_file/`)
        .then((res) => {
          this.loading = false;
          this.message = "Dane zostały wysłane do importu.";
          if (res) {
            const intervalId = setInterval(() => {
              api
                .get(`task-status/${res.data.task_id}/`)
                .then((response) => {
                  this.$emit(
                    "updateFileIsImportedStatus",
                    response.data.status
                  );

                  // Sprawdź, czy status jest "completed"
                  if (response.data.status === "completed") {
                    this.message = "Import danych zakończył się sukcesem.";
                    this.updateSnackbar({
                      open: true,
                      text: "Import danych zakończył się sukcesem.",
                      color: "success",
                    });

                    clearInterval(intervalId); // Zakończ interwał
                    this.$emit("updateFileIsImported", null);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  clearInterval(intervalId); // Zakończ interwał w przypadku błędu
                });
            }, 5000);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.message = "Wystąpił błąd przy imporcie pliku.";
          console.log(err.status, " ---- ", err.data);
        });
    },
    closeModal() {
      this.showModal = false;
      this.message = "";
    },
    closeImportModal() {
      this.showImportModal = false;
      this.message = "";
      this.idToVerify = false;
      this.chosenFileId = null;
    },
  },
  created() {
    this.getFiles(this.params).then((res) => {
      this.userName = res.length ? res[0]["user_id"]["email"] : "";
    });
  },
  computed: {
    shouldShowButton() {
      return (
        this.formattedErrorReport !== 0 &&
        this.verify === false &&
        this.import === false &&
        ((this.status === "pending" && this.statusID !== this.idToVerify) ||
          this.status === "completed")
      );
    },
  },
  watch: {
    shouldShowButton(newValue) {
      this.showButton = newValue;
    },
    fileIsValidated(newVal) {
      if (newVal) {
        this.getFiles(this.params).then((res) => {
          this.userName = res.length ? res[0]["user_id"]["email"] : "";
        });
      }
    },
    fileIsImported(newVal) {
      if (newVal) {
        this.getFiles(this.params).then((res) => {
          this.userName = res.length ? res[0]["user_id"]["email"] : "";
        });
      }
    },
    status(newVal) {
      if (newVal === "completed") {
        this.getFiles(this.params).then((res) => {
          this.userName = res.length ? res[0]["user_id"]["email"] : "";
        });
      }
    },
    statusID(newVal) {
      if (newVal && this.status === "completed") {
        this.getFiles(this.params).then((res) => {
          this.userName = res.length ? res[0]["user_id"]["email"] : "";
        });
      }
    },
    showModal(newVal) {
      if (newVal) {
        if (this.statusID !== this.idToVerify) {
          this.isDisabled = false;
        }
      }
    },
    statusImport(newVal) {
      if (newVal === "completed") {
        this.getFiles(this.params).then((res) => {
          this.userName = res.length ? res[0]["user_id"]["email"] : "";
        });
      }
    },
  },
  mounted() {
    api
      .get(`/users/current_user/`)
      .then((response) => {
        this.company = response.data;
        this.companyId = response.data.company_users.id;
        this.is_staff = response.data.is_staff;
        this.is_newest = response.data.is_newest;
        if (this.company.company_users.isRaise === null) {
          this.dialog = true;
        } else {
          return null;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
.btnHover:hover {
  cursor: pointer;
  text-decoration: underline;
}
.icon-wrapper {
  display: flex;
  gap: 2px;
}
</style>
