<template>
  <v-card style="max-height: 80vh; min-width: 50vw">
    <v-card-title v-if="swd.company">
      Zarządzaj kodami SWD firmy <strong class="ml-2">{{ swd.company }}</strong>
    </v-card-title>
    <v-card-title v-else>Zarządzaj kodami SWD nowej firmy</v-card-title>
    <v-card-text>
      <v-row
        v-for="(kod, i) in edited.concat(newest)"
        :key="`swd-${i}`"
        class="mb-5 align-center"
      >
        <v-text-field
          label="Kod SWD"
          variant="underlined"
          v-model="kod.kod_sw"
          hide-details="auto"
        />
        <v-text-field
          label="Oddział Wojewódzki"
          variant="underlined"
          v-model="kod.oddzial_ow"
          hide-details="auto"
        />
        <v-tooltip anchor="top">
          <template v-slot:activator="{ props }">
            <v-btn
              variant="text"
              size="large"
              icon
              color="error"
              v-bind="props"
              @click="deleteKod(kod.id, i)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>usuń</span>
        </v-tooltip>
      </v-row>
      <v-row class="justify-center m-0">
        <v-btn block color="success" @click="newKod">
          <v-icon class="mr-2">mdi-plus-circle</v-icon>dodaj kolejny kod
        </v-btn>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" text @click="confirmChanges()"> Zatwierdź </v-btn>
      <v-btn color="primary" text @click="cancel()"> Anuluj </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EditSwd",
  props: ["swd"],
  data() {
    return {
      edited: [],
      newest: [],
      deleted: [],
    };
  },
  methods: {
    newKod() {
      this.newest.push({ kod_sw: "", oddzial_ow: "" });
    },
    deleteKod(id, index) {
      if (id) {
        this.edited.splice(index, 1);
        this.deleted.push(id);
      } else {
        this.newest.splice(index - this.edited.length, 1);
      }
    },
    confirmChanges() {
      this.$emit("finished", this.edited, this.newest, this.deleted);
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
  created() {
    let copySWD = JSON.parse(JSON.stringify(this.swd));
    this.edited = copySWD.edited;
    this.newest = copySWD.newest;
    this.deleted = copySWD.deleted;
  },
};
</script>

<style></style>
