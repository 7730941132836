<template>
  <v-card style="min-height: 50vh">
    <v-card-text>
      Zakładka pozwalająca na wgranie pliku w formacie .xls lub .xlsx danego
      świadczeniodawcy po wybraniu odpowiedniego podmiotu. Po wgraniu załącznika
      podlega on walidacji i zwracana jest informacja o nieprawidłowych
      wartościach. Istnieje też możliwość importu danych.
      <v-row class="mt-8 mx-8" align="center">
        <v-col sm="12" md="5" class="text-md-right text-sm-left">
          <span style="font-weight: bold"> Wybierz świadczeniodawcę:</span>
        </v-col>
        <v-col sm="12" md="7" class="text-left">
          <user-select @set-user="setUser" />
        </v-col>
      </v-row>
      <span v-if="user">
        <import-form :userId="user.id" :company="user.company_users" />
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import ImportForm from "../importModule/ImportForm.vue";
import UserSelect from "./UserSelect.vue";

export default {
  name: "ImportFile",
  components: { ImportForm, UserSelect },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    setUser(item) {
      this.user = item;
    },
  },
};
</script>

<style></style>
