import api from "../../services/api";

const state = {
  kodsw: [],
};

const getters = {
  kodsw: (state) => {
    return state.kodsw;
  },
};

const actions = {
  getKodsw({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`kodsw/`)
        .then((response) => {
          commit("setKodsw", response.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getMyKodsw({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`kodsw/get_my_kods/`)
        .then((response) => {
          commit("setKodsw", response.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  addKodsw({ commit }, newSWD) {
    return new Promise((resolve, reject) => {
      api
        .post(`kodsw/`, newSWD)
        .then((response) => {
          commit("createKodsw", response.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  editKodsw({ commit }, changedSWD) {
    return new Promise((resolve, reject) => {
      api
        .put(`kodsw/${changedSWD.id}/`, changedSWD.data)
        .then((response) => {
          commit("alterKodsw", response.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removeKodsw({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`kodsw/${id}/`)
        .then((response) => {
          commit("deleteKodsw", id);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

const mutations = {
  setKodsw(state, response) {
    state.kodsw = response;
  },
  createKodsw(state, response) {
    state.kodsw.push(response);
  },
  alterKodsw(state, response) {
    const record = state.kodsw.findIndex((element) => {
      return element.id == response.id;
    });
    state.kodsw[record] = response;
  },
  deleteKodsw(state, id) {
    const record = state.kodsw.findIndex((element) => {
      return element.id == id;
    });
    state.kodsw.splice(record, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
