import { createStore } from "vuex";
import currentUser from "./modules/currentUser";
import users from "./modules/users";
import bug from "./modules/bug";
import kodsw from "./modules/kodsw";
import questions from "./modules/questions";
import indicators from "./modules/indicators";
import materials from "./modules/materials";

export default createStore({
  modules: {
    currentUser,
    users,
    bug,
    kodsw,
    questions,
    indicators,
    materials,
  },
});
