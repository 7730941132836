import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
loadFonts();

import LoginLayout from "./LoginLayout";
import DefLayout from "./DefLayout";
import MainLayout from "./MainLayout";
import setup from "../interceptors";
import CustomModal from "../src/components/layoutComponents/CustomModal";

// wtyczki do komponentów
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import Toast from "vue-toastification";
import VueApexCharts from "vue3-apexcharts";
import "vue-toastification/dist/index.css";

import { VueEditor } from "vue3-editor";
import "@mdi/font/css/materialdesignicons.css";

const app = createApp(App).use(router).use(store).use(vuetify);
setup();

app.component("DefLayout", DefLayout);
app.component("LoginLayout", LoginLayout);
app.component("MainLayout", MainLayout);

app.component("DatePicker", Datepicker);
app.component("CustomSelect", vSelect);
app.component("CustomModal", CustomModal);
app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 5,
  newestOnTop: true,
});
app.component("VueEditor", VueEditor);
app.use(VueApexCharts);

app.mount("#app");
