<template>
  <div>
    <!-- <v-dialog v-model="flagTrue" persistent>
      <v-card>
        <v-card-text class="text-center">
          Szanowni Państwo,
          <br />
          Informujemy, że szablony Excel oraz instrukcje ich wypełniania zostaną
          <br />
          udostępnione na stronie aplikacji w dniu 15 lutego.
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-container fluid style="padding-top: 5%">
      <v-row align="center" justify="center" dense>
        <v-col>
          <v-card class="ma-auto pa-4" max-width="500" max-height="650">
            <v-card-title class="align-center justify-center">
              <v-img
                @click="$router.push('/')"
                :src="require('../../assets/logo_bez_tla.png')"
                class="my-3"
                contain
                height="115"
                style="cursor: pointer"
              />
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="pb-10">
                      <div style="text-align: center">
                        <h1 class="font-weight-light">Zaloguj się</h1>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pa-0">
                      <v-text-field
                        label="Email"
                        outlined
                        v-model="credentials.login"
                        :append-icon="'mdi-email'"
                        v-on:keydown.enter="logIn()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pa-0">
                      <v-text-field
                        v-model="credentials.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Hasło"
                        counter
                        @click:append="show1 = !show1"
                        v-on:keydown.enter="logIn()"
                      ></v-text-field>
                      <!-- <v-text-field
                      label="Hasło"
                      placeholder=""
                      filled
                      v-model="credentials.password"
                    ></v-text-field> -->
                    </v-col>
                    <v-row no-gutters style="font-size: 16px">
                      <v-col sm="3" md="6" class="pa-0">
                        <p class="font-weight-light text-right">
                          Nie masz konta?
                        </p>
                      </v-col>
                      <v-col sm="3" md="6" class="pa-0">
                        <p
                          class="font-weight-light text-left pl-3"
                          style="color: #86d1cd; cursor: pointer"
                          @click="goToRegister()"
                        >
                          Jak uzyskać dostęp?
                        </p>
                      </v-col>
                      <v-col>
                        <div
                          class="font-weight-light text-center"
                          style="color: #86d1cd; cursor: pointer"
                          @click="$router.push('/odzyskanie_hasla')"
                        >
                          Zapomniałeś hasła?
                        </div>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                rounded="pill"
                color="white"
                style="width: 80%; background: #17728d"
                @click="logIn()"
              >
                Zaloguj</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog">
        <v-card>
          <v-card-text>Niepoprawny email lub hasło! </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="dialog = false">Zamknij</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <custom-modal
      :flag="flag"
      @close-modal="
        () => {
          flag = false;
        }
      "
    >
      <template v-slot:message>
        <div class="text-justify">
          Konta są zakładane automatycznie przez system. Każdy użytkownik, do
          którego drogą mailową została wysłana prośba o przekazanie danych
          posiada konto w aplikacji.<br />
          W razie nieotrzymania przez użytkownika Wniosku o udostępnienie
          Danych, należy wysłać e-mail z prośbą o założenie konta na adres
          <strong> appRKP@aotm.gov.pl</strong>.<br />
          Instrukcja logowania dostępna jest do pobrania na stronie głównej.
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import auth from "../../auth";
import CustomModal from "../layoutComponents/CustomModal.vue";

export default {
  name: "AuthorizationForm",
  components: {
    CustomModal,
  },
  data() {
    return {
      credentials: {
        login: "",
        password: "",
      },
      show1: false,
      rules: {
        required: (value) => !!value || "Wymagane.",
        min: (v) => v.length >= 8 || "Conajmniej 8 znaków",
      },
      dialog: false,
      flag: false,
      // flagTrue: true,
    };
  },
  methods: {
    goToRegister() {
      // this.$router.push("/rejestracja");
      this.flag = true;
    },
    logIn() {
      let credentials = {
        email: this.credentials.login,
        password: this.credentials.password,
      };
      auth
        .login(this, credentials)
        .then(() => {})
        .catch((error) => {
          this.dialog = true;
          console.log(this.dialog);
          console.log("yooooo", error.response);
        });
    },
  },
};
</script>

<style></style>
