<template>
  <template v-for="(question, id) in QAList" :key="id">
    <span v-if="question.type === type">
      <li>{{ question.question }}</li>
      <div class="my-2" v-html="question.answear"></div>
    </span>
  </template>
</template>

<script>
export default {
  name: "QuestionElement",
  props: {
    QAList: Array,
    type: String,
  },
};
</script>

<style></style>
