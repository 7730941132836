<template><span></span></template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "CustomSnackbar",
  props: ["snackbar"],
  watch: {
    snackbar: {
      handler(newValue) {
        const toast = useToast();
        const options = {
          position: "bottom-right",
          timeout: 20000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        };
        switch (newValue.color) {
          case "success":
            toast.success(newValue.text, options);
            break;
          case "info":
            toast.info(newValue.text, options);
            break;
          case "warning":
            toast.warning(newValue.text, options);
            break;
          case "error":
            toast.error(newValue.text, options);
            break;
          default:
            toast(newValue.text, options);
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
