<template>
  <v-btn color="primary" variant="outlined" @click="getTemplateTemp()">
    Pobierz szablon pliku
  </v-btn>
</template>

<script>
export default {
  name: "TemplateButton",
  data() {
    return {};
  },
  props: {
    isSrkValue: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    async getTemplateTemp() {
      try {
        let fileUrl;
        if (this.isSrkValue === true) {
          fileUrl = "materials/szablon_z_SRK.xlsx";
        } else if (this.isSrkValue === false) {
          fileUrl = "materials/szablon_bez_SRK.xlsx";
        } else {
          fileUrl = "materials/szablon_bez_SRK.xlsx";
        }

        let anchor = document.createElement("a");
        anchor.href = process.env.VUE_APP_API_ENDPOINT2 + `media/${fileUrl}`;
        anchor.download = `raportKadr_szablon.xlsx`;
        document.body.appendChild(anchor);
        await anchor.click();
      } catch (err) {
        console.error("Error downloading file:", err);
      }
    },
  },
};
</script>

<style></style>
